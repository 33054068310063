import {Button, Modal, Form as BootForm} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import axios from 'axios'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import LoadingButton from '../../../layout/components/LoadingButton'
import {useState} from 'react'

interface Props {
  createFlowActions: {isShowModal: boolean}
  setCreateFlowActions: any
  fetchFlowById: any
}

interface CreateFlowProps {
  flowTitle: string
  flowDescription: string
}

interface CreateFlowActionsProps {
  isProcessingCreateFlow: boolean
}

const CreateFlow = ({createFlowActions, setCreateFlowActions, fetchFlowById}: Props) => {
  const [createFlowAction, setCreateFlowAction] = useState<CreateFlowActionsProps>({
    isProcessingCreateFlow: false,
  })
  const initialValues: CreateFlowProps = {
    flowTitle: '',
    flowDescription: '',
  }

  const createNewFlow = async () => {
    setCreateFlowAction({...createFlowAction, isProcessingCreateFlow: true})
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_SAVE_FLOW}`,
        {
          flowData: JSON.stringify({
            name: values.flowTitle,
            description: values.flowDescription,
          }),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        fetchFlowById(response.data.flow_id, 'new')
        toast.success(response.data.message)
      })
      .catch((error) => {
        toast.error(`Error : ${error.response.data.error}`)
      })
    formik.resetForm()
    setCreateFlowAction({...createFlowAction, isProcessingCreateFlow: false})
    setCreateFlowActions({...createFlowActions, isShowModal: false})
  }

  const validationSchema = Yup.object({
    flowTitle: Yup.string().required().label('Flow Title'),
    flowDescription: Yup.string().required().label('Flow Description'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createNewFlow()
    },
  })

  const {values, errors, getFieldProps} = formik

  return (
    <Modal show={createFlowActions.isShowModal} centered>
      <Modal.Header className='p-4 border-0'>
        <h2 className='border-0 m-auto'>Create New Flow</h2>
        <button
          type='button'
          className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
          onClick={() => {
            setCreateFlowActions({...createFlowActions, isShowModal: false})
            formik.resetForm()
          }}
        >
          <KTIcon iconName='cross' className='fs-2' />
        </button>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form>
            <div className='current'>
              <div className='w-100'>
                <BootForm.Group controlId='validationFormikStepTitle'>
                  <BootForm.Label
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                    for='flowTitle'
                  >
                    <span className='required'>Flow Title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </BootForm.Label>
                  <BootForm.Control
                    type='text'
                    isInvalid={!!errors.flowTitle}
                    {...getFieldProps('flowTitle')}
                  />
                  <BootForm.Control.Feedback type='invalid'>
                    {errors.flowTitle}
                  </BootForm.Control.Feedback>
                </BootForm.Group>
                <BootForm.Group controlId='validationFormikStepTitle' className='mt-5'>
                  <BootForm.Label
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                    for='flowDescription'
                  >
                    <span className='required'>Flow Description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </BootForm.Label>
                  <BootForm.Control
                    as='textarea'
                    rows={8}
                    isInvalid={!!errors.flowDescription}
                    {...getFieldProps('flowDescription')}
                  />
                  <BootForm.Control.Feedback type='invalid'>
                    {errors.flowDescription}
                  </BootForm.Control.Feedback>
                </BootForm.Group>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <LoadingButton
          isLoading={createFlowAction.isProcessingCreateFlow}
          submitButtonAction={() => {
            formik.handleSubmit()
          }}
          buttonClasses={'btn-sm'}
        >
          Create
        </LoadingButton>
        {/* <Button
          type='button'
          variant='light'
          className='btn-sm'
          onClick={() => {
            setCreateFlowActions({...createFlowActions, isShowModal: false})
            formik.resetForm()
          }}
        >
          <KTIcon iconName='cross' className='fs-3 ms-2 me-0' /> Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default CreateFlow
