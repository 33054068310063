import * as React from 'react'

interface Props {
  classes: string
}

const ApiIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <g data-name='03 Api'>
        <path
          d='M28.435 12.977a12.738 12.738 0 0 0-1.506-3.631l1.417-2.091-3.6-3.6-2.091 1.415a12.778 12.778 0 0 0-3.633-1.506l-.475-2.477h-5.094l-.475 2.477A12.772 12.772 0 0 0 9.346 5.07L7.255 3.654l-3.6 3.6L5.07 9.346a12.747 12.747 0 0 0-1.505 3.631l-2.478.476v5.093l2.478.477a12.759 12.759 0 0 0 1.505 3.631l-1.416 2.09 3.6 3.6 2.091-1.417a12.715 12.715 0 0 0 3.632 1.506l.475 2.478h5.094l.475-2.478a12.721 12.721 0 0 0 3.633-1.506l2.09 1.417 3.6-3.6-1.417-2.09a12.75 12.75 0 0 0 1.506-3.631l2.478-.477v-5.093ZM16 24.023A8.024 8.024 0 1 1 24.023 16 8.023 8.023 0 0 1 16 24.023Z'
          style={{}}
          fill='#5eb8e1'
          data-original='#5eb8e1'
          className=''
        />
        <path
          d='M16 5.758A10.242 10.242 0 1 0 26.242 16 10.242 10.242 0 0 0 16 5.758Zm0 18.265A8.024 8.024 0 1 1 24.023 16 8.023 8.023 0 0 1 16 24.023Z'
          style={{}}
          fill='#5555a5'
          data-original='#5555a5'
        />
        <path
          d='m13.878 16.405-.985-2.448a.5.5 0 0 0-.462-.313.5.5 0 0 0-.463.309L10.953 16.4l-.661 1.6a.5.5 0 1 0 .925.382l.532-1.288h1.331l.518 1.284a.5.5 0 1 0 .927-.374Zm-1.2-.306h-.516l.261-.633ZM20.306 13.3a.5.5 0 0 0-.5.5v4.4a.5.5 0 0 0 1 0v-4.4a.5.5 0 0 0-.5-.5ZM17.155 13.3h-1.273a.5.5 0 0 0-.5.5v4.4a.5.5 0 0 0 1 0v-1.7h.773a1.6 1.6 0 0 0 0-3.2Zm0 2.2h-.773v-1.2h.773a.6.6 0 0 1 0 1.2Z'
          style={{}}
          fill='#837aff'
          data-original='#837aff'
        />
      </g>
    </g>
  </svg>
)
export default ApiIcon
