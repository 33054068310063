import {ReactNode} from 'react'
import {Button} from 'react-bootstrap'

interface LoadingButtonProps {
  isLoading?: boolean
  submitButtonAction: any
  loadingButtonTitle?: string
  children: ReactNode
  buttonClasses: string
  setDisabled?: boolean
}

const LoadingIconButton = ({
  children,
  isLoading,
  submitButtonAction,
  buttonClasses,
  setDisabled,
}: LoadingButtonProps) => {
  return (
    <>
      <Button
        type='button'
        variant='primary'
        className={`${buttonClasses} position-relative`}
        onClick={submitButtonAction}
        disabled={isLoading || setDisabled}
      >
        {isLoading && (
          <span className='indicator-progress d-flex align-items-center position-absolute top-0 bottom-0'>
            <span className='spinner-border spinner-border align-middle'></span>
          </span>
        )}
        {children}
      </Button>
    </>
  )
}
export default LoadingIconButton
