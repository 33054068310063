import {useEffect, useState} from 'react'
import {Card, Col, Image, Row, Form as BootForm, Modal, Button} from 'react-bootstrap'
import '../../App.css'
import axios from 'axios'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {AiOutlineEye} from 'react-icons/ai'
import {BsTrash} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import loadingimg from '../../../_metronic/assets/loading.gif'
import DatasourceTypes from '../../../_metronic/layout/components/DatasourceTypes'
import FileManager from '../fileupload/FileManager'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

function DataSourceContainer({type}) {
  const [data, setData] = useState([])
  const [searchDataSourceName, setsearchDataSourceName] = useState('')
  const [previousPaths, setPreviousPaths] = useState([])
  const [previousFolders, setPreviousFolders] = useState([])
  const [allDataSources, setAllDataSources] = useState([])
  const [dataSourceIdValue, setDataSourceIdValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [dataSources, setDataSources] = useState([])
  const [index, setIndex] = useState(0)
  const [allfolders, setAllFolders] = useState(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const [dataSourceName, setDataSourceName] = useState()
  const [dataSourcepath, setDataSourcepath] = useState(false)
  const [editid, seteditid] = useState('')
  const [editName, seteditName] = useState(undefined)
  const [datasourceActions, setDatasourceActions] = useState({actionType: ''})
  const [datasourcetype, setDatasourceType] = useState('Files')
  let [currentPath, setCurrentPath] = useState('uploaded_folders')
  const [url, setUrl] = useState('')
  const baseURL = `${process.env.REACT_APP_FILE_MANAGER_LIST}/file_manager`

  const getData = () => {
    axios.get(baseURL).then((response) => {
      setData(response.data.files_list)
    })
  }

  const getAllDataSources = async () => {
    const result = await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/datasources`)
    setAllDataSources(
      Object.entries(result.data).map((key) => {
        return {...key[1], key: key[0]}
      })
    )
    setIsLoading(false)
  }

  const deleteDataSources = (id) => {
    var answer = window.confirm('Are you sure you want to delete this?')
    if (answer) {
      fetch(`${process.env.REACT_APP_DATASOURCE_LIST}/datasources/${id}`, {
        method: 'DELETE',
      })
        .then((response) => response.json())
        .then(() => {
          getAllDataSources()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const editDataSources = async () => {
    const formData = new FormData()
    formData.append('datasource_id', editid)
    formData.append('new_folder_path', dataSourcepath)
    await axios.post(`${process.env.REACT_APP_DATASOURCE_LIST}/edit_datasource`, formData)
  }

  const organizeFiles = (filePaths) => {
    const root = {name: 'root', children: []}

    filePaths.forEach((filePath) => {
      const pathParts = filePath.split('/')

      let currentNode = root

      pathParts.forEach((part, index) => {
        if (!currentNode.children) {
          currentNode.children = []
        }

        const isLastPart = index === pathParts.length - 1
        const isFolder = !isLastPart

        let node = currentNode.children.find((n) => n.name === part)
        if (!node) {
          node = {name: part, children: []}
          currentNode.children.push(node)
        }

        if (isFolder) {
          currentNode = node
        } else {
          node.filePath = filePath
        }
      })
    })
    if (root.children.length === 1 && root.children[0].name === 'uploaded_folders') {
      return root.children[0].children
    }

    return root.children
  }
  const getDataSourceByTd = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_DATASOURCE_LIST}/datasources/${id}`)
      .then((response) => {
        setDataSourceIdValue(response.data)
        setDataSourceName(response?.data.name)
      })
  }

  const handleFolder = (name) => {
    setSearchTerm('')
    // const path = data.find((item) => item.includes(name))
    // const parts = path.split('/')
    // const indexfolder = parts.indexOf(name)
    // const targetParts = parts.slice(0, indexfolder + 1)
    // const targetPath = targetParts.join('/')
    const newCurrentPath = `${currentPath}/${name}`
    setPreviousPaths([...previousPaths, currentPath])
    setCurrentPath(newCurrentPath)

    const selectedFolder = allfolders?.filter((row) => row.name === name)
    const tempData1 = selectedFolder?.map((row) => row.children)
    const child = tempData1[0]?.filter((row) => row?.name !== '')
    setAllFolders(child)
    setPreviousFolders([...previousFolders, allfolders])

    return newCurrentPath
  }
  useEffect(() => {
    if (data.length > 0 && allfolders === undefined) {
      const tempData = organizeFiles(data).filter((row) => row?.name !== '')
      setAllFolders(tempData)
    }
  }, [data, allfolders, currentPath])

  useEffect(() => {
    if (dataSourceIdValue) {
      files()
    }
  }, [dataSourceIdValue])

  const files = () => {
    const tempdata = dataSourceIdValue
    if (tempdata) {
      const file_paths = JSON.parse(tempdata.file_paths)
      const result = organizeFiles(file_paths).filter((item) => item.name !== '')
      setDataSources(result)
    }
  }

  const [viewPreviousPath, setViewPreviousPath] = useState([])
  const [viewCurrentPath, setViewCurrentPath] = useState('uploaded_folders')
  const [viewPreviousFolder, setViewPreviousFolder] = useState([])

  const handleViewFolder = (name) => {
    const newCurrentPath = `${currentPath}/${name}`

    setViewPreviousPath([...viewPreviousPath, viewCurrentPath])
    setViewCurrentPath(newCurrentPath)

    const selectedFolder = dataSources?.filter((row) => row.name === name)
    const tempData1 = selectedFolder?.map((row) => row.children)
    const child = tempData1[0]?.filter((row) => row?.name !== '')

    setDataSources(child)
    setViewPreviousFolder([...viewPreviousFolder, dataSources])

    return newCurrentPath
  }

  const goBack = () => {
    if (previousPaths.length > 0) {
      const prevPath = previousPaths[previousPaths.length - 1]
      setCurrentPath(prevPath)
      setPreviousPaths(previousPaths.slice(0, previousPaths.length - 1))
      const lastElement = previousFolders.pop()
      setAllFolders(lastElement)
    }
  }

  const handleBack = () => {
    if (viewPreviousPath.length > 0) {
      const prevPath = viewPreviousPath[viewPreviousPath.length - 1]
      setViewCurrentPath(prevPath)
      setViewPreviousPath(viewPreviousPath.slice(0, viewPreviousPath.length - 1))
      const lastElement = viewPreviousFolder.pop()
      setDataSources(lastElement)
    }
  }

  useEffect(() => {
    getAllDataSources()
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  const filterDataSource = allDataSources?.filter((item) =>
    item.name.toLowerCase().includes(searchDataSourceName.toLocaleLowerCase())
  )

  const initialValues = {
    dataSourceName: '',
    dataSourceType: '',
    dataSourcePath: '',
  }

  // const createDataSpurceValidationSchema = Yup.object({
  //   dataSourceName: Yup.string().required().label('Datasource Name'),
  //   dataSourceType: Yup.string().required().label('Datasource Type'),
  //   dataSourcePath: Yup.string().required().label('Datasource'),
  // })

  const formik = useFormik({
    initialValues,
    // validationSchema: createDataSpurceValidationSchema,
    onSubmit: async (values) => {
      console.log('values.dataSourcePath===', values.dataSourcePath)
      await axios
        .get(
          `${process.env.REACT_APP_DATASOURCE_LIST}/process_directory?filepath=${
            values.dataSourcePath || ' '
          }&datasource_name=${values.dataSourceName}${
            datasourcetype !== 'Files' ? `&type=${datasourcetype}` : ''
          }${
            datasourcetype !== 'Files'
              ? ` &max_depth=${datasourcetype === 'webpages' ? '1' : '2'}`
              : ''
          }${datasourcetype !== 'Files' ? `&url=${url}` : ''}`
        )
        .then((response) => {
          toast.success(response.data.message)
          formik.resetForm()
          setDatasourceActions({actionType: ''})
        })
        .catch((error) => {
          toast.error(`Error : ${error.response.data.error}`)
        })
    },
  })

  const {values, errors, getFieldProps} = formik

  const setFieldValue = (field, value) => {
    formik.setFieldValue([field], value)
    console.log('ferfrf43344', field)
    setIndex(1)
    setDataSourcepath(value)
  }

  const handleModal = () => {
    formik.resetForm()
    setDatasourceActions({actionType: ''})
  }

  return (
    <div>
      <div className='card card-flush'>
        <div className='card-header p-10 pb-0 bg-white'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-circle me-5'>
              <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed'>
                <i className='ki-duotone ki-abstract-47 fs-2x text-primary'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h2 className='mb-1'>Datasources</h2>
              <div className='text-muted fw-bold'></div>
            </div>
          </div>
        </div>
        <div className='card-header py-8'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search Data Source'
                onChange={(e) => setsearchDataSourceName(e.target.value)}
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-flex  btn-light-primary'
                onClick={() => setDatasourceActions({...datasourceActions, actionType: 'view'})}
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Create Datasource
              </button>
            </div>
          </div>
        </div>
        <div
          className='dataTables_wrapper dt-bootstrap4 no-footer dataSource-table'
          id='kt_file_manager_list_wrapper'
        >
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <img
                style={{height: '100px', width: '100px', objectFit: 'cover'}}
                src={loadingimg}
                alt='loading...'
              />
            </div>
          ) : (
            <Row xs md={3} className='g-5 mb-5'>
              {filterDataSource.length ? (
                filterDataSource.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer'>
                      <Card.Body className='p-3'>
                        <div className='row no-gutters'>
                          <div className='col-md-auto'>
                            <div className='symbol symbol-75px m-0 w-100'>
                              <span className='symbol-label bg-light-primary'>
                                <i className='ki-duotone ki-abstract-26 fs-3x text-primary'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </div>
                          </div>
                          <div className='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                            <p className='card-title pt-1'>{_?.name}</p>
                            <div className='d-flex gap-2 col-12'>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary px-3'
                                data-kt-menu-placement='bottom-end'
                                data-bs-target='#kt_modal_view_app'
                                data-bs-toggle='modal'
                                onClick={() => getDataSourceByTd(_.key)}
                              >
                                <AiOutlineEye className='openeyeIcon' />
                              </button>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-success px-3'
                                data-kt-menu-placement='bottom-end'
                                data-bs-target='#kt_modal_edit_app'
                                data-bs-toggle='modal'
                                onClick={() => {
                                  getDataSourceByTd(_.key)
                                  seteditid(_.key)
                                }}
                              >
                                <BiEdit className='openeyeIcon' />
                              </button>
                              <button
                                onClick={() => deleteDataSources(_.key)}
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-danger px-3'
                                data-kt-menu-placement='bottom-end'
                              >
                                <BsTrash className='openeyeIcon' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className='col-12 col-md-12 text-center'>
                  <Image
                    src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                    className='mw-200px'
                  />
                  <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                  <div className='fs-6 pb-5'>
                    Start creating new folders or uploading a new file!
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>

      <Modal
        show={datasourceActions.actionType === 'view'}
        dialogClassName='mw-900px'
        centered
        onHide={() => handleModal()}
      >
        <Modal.Header className='p-5 border-0' closeButton>
          <h2 className='mb-0 m-auto'>Create DataSource</h2>
        </Modal.Header>

        <Modal.Body className='p-3'>
          <div className='flex-row-fluid'>
            <FormikProvider value={formik}>
              <Form className='mx-auto w-100 pt-5 pb-0 p-5'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <BootForm.Group controlId='validationFormikStepTitle'>
                      <BootForm.Label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        for='dataSourceName'
                      >
                        <span className='required'>Datasource Name</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Specify your unique app name'
                        ></i>
                      </BootForm.Label>
                      <BootForm.Control
                        type='text'
                        isInvalid={!!errors.dataSourceName}
                        {...getFieldProps('dataSourceName')}
                        className='form-control form-control-lg form-control-solid'
                      />
                      <BootForm.Control.Feedback type='invalid'>
                        {errors.dataSourceName}
                      </BootForm.Control.Feedback>
                    </BootForm.Group>
                  </div>
                </div>
                <div className='mt-3'>
                  <DatasourceTypes
                    fieldName='dataSourceType'
                    actionType={values.dataSourceType}
                    setActionType={setFieldValue}
                    setDatasourceType={setDatasourceType}
                  />

                  <BootForm.Control.Feedback
                    type='invalid'
                    className={`${!errors.dataSourceType ? 'd-none' : 'd-block'}`}
                  >
                    {errors.dataSourceType}
                  </BootForm.Control.Feedback>
                </div>
                <div className='mt-8'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>
                      {datasourcetype === 'Files' ? 'Attach Files or Folder' : 'Enter Url'}
                    </span>
                    <span
                      className='ms-1'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    >
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>

                  <div className='mh-300px overflow-scroll'>
                    {datasourcetype === 'Files' ? (
                      <FileManager
                        type='selectable'
                        fieldName='dataSourcePath'
                        fieldValue={values.dataSourcePath}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <input
                        type='text'
                        className='form-control form-control form-control-solid'
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    )}
                  </div>
                  {/* <BootForm.Control.Feedback
                    type='invalid'
                    className={`${!errors.dataSourcePath ? 'd-none' : 'd-block'}`}
                  >
                    {errors.dataSourcePath}
                  </BootForm.Control.Feedback> */}
                </div>
              </Form>
            </FormikProvider>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => formik.handleSubmit()}
          >
            Create
          </button>
          {/* <button
            type='button'
            className='btn btn-secondary btn-sm ms-3'
            onClick={() => {
              formik.resetForm()
              setDatasourceActions({...datasourceActions, actionType: ''})
            }}
          >
            Close
          </button> */}
        </Modal.Footer>
      </Modal>

      <div className='modal fade' id='kt_modal_view_app' tabindex='-1' aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>View Datasource</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body py-lg-5 px-lg-10'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='row'>
                      <div className='col-md-12 fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                          <span className='required'>Datasource Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Specify your unique app name'
                          ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control form-control-solid'
                          name='name'
                          value={dataSourceIdValue?.name}
                        />
                      </div>
                    </div>
                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                        <span className='required'>Datasouce Files & Folders</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Select your app category'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </span>
                      </label>
                      {viewCurrentPath === 'uploaded_folders' ? null : (
                        <a className='btn btn-link mb-2' onClick={handleBack}>
                          <i className='ki-duotone ki-left fs-2'></i>Back
                        </a>
                      )}
                      <Row xs md={4} className='g-6 mb-5 flex-row flex-nowrap overflow-scroll'>
                        {Object.keys(dataSources).length ? (
                          dataSources.map((_) => (
                            <Col>
                              <Card className='text-center border border-secondary cursor-pointer'>
                                {!_?.name.match(/\.[^\/]+$/) && (
                                  <span
                                    className='badge badge-light-primary position-absolute'
                                    style={{top: 5, right: 5}}
                                  >
                                    {_?.children.length} Item{_?.children.length > 1 && 's'}
                                  </span>
                                )}
                                <Card.Img
                                  variant='top'
                                  className='m-auto pt-5'
                                  style={{width: 80}}
                                  src={
                                    _?.name.match(/\.[^\/]+$/)
                                      ? toAbsoluteUrl('/media/file.png')
                                      : toAbsoluteUrl('/media/folder/folder.png')
                                  }
                                  onClick={
                                    _?.name.match(/\.[^\/]+$/)
                                      ? null
                                      : () => handleViewFolder(_?.name)
                                  }
                                />
                                <Card.Body className='p-3 pt-0'>
                                  <p className='card-title text-truncate'>{_?.name}</p>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))
                        ) : (
                          <Col className='col-12 col-md-12 text-center'>
                            <Image
                              src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                              className='mw-200px'
                            />
                            <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                            <div className='fs-6 pb-5'>
                              Start creating new folders or uploading a new file!
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_edit_app' tabindex='-1' aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>Edit Datasource</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='row'>
                      <div className='col-md-12 fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                          <span className='required'>Datasource Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Specify your unique app name'
                          ></i>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control form-control-solid'
                          name='name'
                          value={dataSourceIdValue?.name}
                        />
                      </div>
                    </div>
                    <div className='mh-300px overflow-scroll'>
                      <FileManager
                        type='selectable'
                        fieldName='dataSourcePath'
                        fieldValue={values.dataSourcePath}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer pt-2 border-0 px-8'>
              <button
                data-bs-dismiss='modal'
                type='button'
                className='btn btn-primary'
                data-kt-stepper-action='next'
                onClick={() => editDataSources()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataSourceContainer
