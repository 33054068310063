/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import CreateFlow from '../../../_metronic/partials/modals/create-new-flow'
import axios from 'axios'
import {
  Button,
  Card,
  Col,
  Form as BootForm,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import DataSourceListing from '../datasource/DataSourceListing'
import LibraryListing from '../library/LibraryListing'
import PromptListing from '../PromptLibrary/PromptListing'
import ActionLibraryListing from '../ActionLibrary/ActionLibraryListing'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LibraryIcon from '../../../_metronic/assets/svgIcon/LibraryIcon'
import ActionIcon from '../../../_metronic/assets/svgIcon/ActionIcon'
import PromptIcon from '../../../_metronic/assets/svgIcon/PromptIcon'
import EditPrompt from '../PromptLibrary/EditPrompt'
import loadingImg from '../../../_metronic/assets/loading.gif'
import ViewActionLibrary from '../ActionLibrary/ViewActionLibrary'
import LoadingButton from '../../../_metronic/layout/components/LoadingButton'
import LoadingIconButton from '../../../_metronic/layout/components/LoadingIconButton'
import {createNewPrompt, getPromptDataById} from '../../services/PromptServices'
import {
  ActionSource,
  CreateFlowProps,
  CreateStep,
  EditPromptProps,
  EditStep,
  FlowSouce,
  ReplyWindow,
  StepActionSource,
  StepsSource,
  ViewActionLibraryProps,
} from './FlowDataTypes'

const createStepsValidationSchema = [
  Yup.object({
    stepName: Yup.string().required().label('Step Title'),
  }),
  Yup.object({
    libraryIds: Yup.array().required().label('Library'),
  }),
  Yup.object({
    libraryIds: Yup.array().required().label('Datasource'),
  }),
  Yup.object({
    promptContent: Yup.string().required().label('Prompt'),
  }),
  Yup.object({
    actions: Yup.array().required().label('Action'),
  }),
]

function Flow() {
  const [flowData, setFlowData] = useState<FlowSouce[] | undefined>(undefined)
  const [createFlowActions, setCreateFlowActions] = useState<CreateFlowProps>({isShowModal: false})
  const [action, setAction] = useState<ActionSource>()
  const [editStepAction, setEditStepAction] = useState<EditStep>({isEditEnabled: false, stepId: ''})
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [visibility, setVisiblity] = useState<string>('')
  const [statuss, setStatuss] = useState<string>('')
  const [showCopied, setShowCopied] = useState(false)
  const [editPrompt, setEditPrompt] = useState<EditPromptProps>({
    isShowModal: false,
    promptId: '',
  })
  const [viewActionLibrary, setViewActionLibrary] = useState<ViewActionLibraryProps>({
    isShowModal: false,
    actionId: '',
  })
  const [showReplyWindow, setShowReplyWindow] = useState<ReplyWindow[]>([])
  const [stepActions, setStepActions] = useState<StepActionSource>({
    type: '',
    validationSchema: 0,
    showModal: false,
    isLoadingSteps: false,
    isProcessingAttachStep: false,
    isProcessingDetachStep: false,
    detachingId: '',
    isProcessingPlayStep: false,
  })

  const fetchFlowById = async (flowId: string, actionType: string = '') => {
    await axios
      .get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_flow/${flowId}`)
      .then((response) => {
        if (actionType) {
          response.data.flowId = flowId
          const filteredFlows = flowData?.filter((flowItem) => flowItem.flowId !== flowId)
          const data = filteredFlows && filteredFlows?.concat(response.data)
          setFlowData(data)
        }

        fetchStepsInfo(response.data.steps, flowId, false)
      })
  }

  const updateFlowVisibility = async (getVisibility: string) => {
    const modifiedFlowData = {...action?.flowData, visibility: getVisibility || visibility}
    const flowDataJson = JSON.stringify(modifiedFlowData)
    await axios
      .put('https://flow-step-w4o7wofqcq-wl.a.run.app/update_flow', {
        flowData: flowDataJson,
        flow_id: action?.flowId,
      })
      .then((response) => {
        fetchFlowById(action?.flowId ?? '', 'new')
        toast.success(response.data.message)
      })
  }

  const updateFlowStatus = async (getStatus: string) => {
    const modifiedFlowData = {...action?.flowData, status: getStatus || statuss}

    const flowDataJson = JSON.stringify(modifiedFlowData)
    await axios
      .put('https://flow-step-w4o7wofqcq-wl.a.run.app/update_flow', {
        flowData: flowDataJson,
        flow_id: action?.flowId,
      })
      .then((response) => {
        fetchFlowById(action?.flowId ?? '', 'new')
        toast.success(response.data.message)
      })
  }
  const handleCopy = (text: string) => {
    if (text !== '') {
      navigator.clipboard.writeText(text)
      setShowCopied(true)
      setTimeout(() => setShowCopied(false), 1500)
    }
  }

  const fetchFlow = async () => {
    await axios.get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_all_flows`).then((response) => {
      const flowKeys = Object.keys(response.data)

      const flowData = flowKeys
        ? flowKeys.map((item) => ({
            ...response.data[item],
            flowId: item,
          }))
        : []
      setFlowData(flowData)
    })
  }

  const deleteFlow = async () => {
    await axios
      .delete(`https://flow-step-w4o7wofqcq-wl.a.run.app/delete_flow/${action?.flowId}`)
      .then((response) => {
        toast.success(response.data.message)
        setDeleteModal(false)
        fetchFlow()
      })
  }

  const deleteStep = async (id: string) => {
    await axios
      .delete(`https://flow-step-w4o7wofqcq-wl.a.run.app/delete_step/${id}`)
      .then((response) => {
        toast.success(response.data.message)
        fetchFlow()
      })
  }

  const fetchStepsInfo = async (stepIds: string[], flowId: string, refresh: boolean = false) => {
    if (stepIds === undefined || !stepIds.length) return
    refresh && setStepActions({...stepActions, isLoadingSteps: true})
    const uniqueDataArray = stepIds.filter((value, index, self) => self.indexOf(value) === index)

    const stepData: StepsSource[] = []
    const data: StepsSource[] = await Promise.all(
      uniqueDataArray.map(async (stepId) => {
        const response = await axios
          .get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_step/${stepId}`)
          .then((response) => response)
          .catch((err) => err)
        if (response.status === 200) {
          response.data.stepId = stepId
          // response.data.name=
          stepData.push(response.data)
          return response.data
        }
        return []
      })
    )

    if (data.length > 0) {
      const newArray = flowData?.map((item) => {
        if (item.flowId === flowId) {
          const filteredStepsData = data.filter((row) => Object.keys(row).length)
          item.stepsData = filteredStepsData
          return item
        }
        return item
      })

      setFlowData(newArray)
    }
    refresh && setStepActions({...stepActions, isLoadingSteps: false})
  }

  useEffect(() => {
    flowData === undefined && fetchFlow()
  }, [])

  const getFlowAttachments = (data: string[], stepId: string, icon: any, info: string) => {
    return data.map((_) => (
      <div className='text-center cursor-pointer rounded bg-white shadow-sm'>
        <div className='p-3 card-body position-relative'>
          <div className='row no-gutters'>
            <div
              className='ps-0 col-auto col-md-auto'
              onClick={() => {
                info === 'Prompt' && setEditPrompt({...editPrompt, isShowModal: true, promptId: _})
                info === 'Action' &&
                  setViewActionLibrary({
                    ...viewActionLibrary,
                    isShowModal: true,
                    actionId: _,
                  })
              }}
            >
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip data-bs-theme='dark'>{info}</Tooltip>}
              >
                <div className='symbol symbol-60px m-0 w-100'>
                  <div className='symbol-label bg-light-primary p-4'>{icon}</div>
                </div>
              </OverlayTrigger>
            </div>
            <div
              className='col col-md text-start ps-0 d-flex flex-column justify-content-around'
              onClick={() => {
                info === 'Prompt' && setEditPrompt({...editPrompt, isShowModal: true, promptId: _})
                info === 'Action' &&
                  setViewActionLibrary({
                    ...viewActionLibrary,
                    isShowModal: true,
                    actionId: _,
                  })
              }}
            >
              <p className='card-title fs-5 mb-0'>{_}</p>
              <div className='d-flex gap-2 col-12'>
                <span className='badge badge-light-primary'>In Progress</span>
              </div>
            </div>
            <div
              className='ps-0 col-auto col-md-auto flex-column row px-1'
              style={{margin: `-6px -8px`}}
            >
              {editStepAction.stepId === stepId && (
                <>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip data-bs-theme='dark'>Detach this {info} from current step</Tooltip>
                    }
                  >
                    <LoadingIconButton
                      isLoading={
                        stepActions.isProcessingDetachStep && _ === stepActions.detachingId
                      }
                      submitButtonAction={() => detachAction(info, stepId, _)}
                      loadingButtonTitle='Attaching...'
                      buttonClasses='btn btn-sm btn-icon btn-light-danger btn-active-color-white rounded'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </LoadingIconButton>
                  </OverlayTrigger>
                  {info === 'Prompt' && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip data-bs-theme='dark'>Edit Prompt</Tooltip>}
                    >
                      <Button
                        className='btn btn-sm btn-icon btn-light-primary btn-active-color-white rounded mt-1'
                        style={{right: '-8px', top: '0px'}}
                        onClick={() =>
                          info === 'Prompt' &&
                          setEditPrompt({...editPrompt, isShowModal: true, promptId: _})
                        }
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Button>
                    </OverlayTrigger>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const getSteps = () => {
    if (stepActions.isLoadingSteps)
      return (
        <div className='d-flex justify-content-center w-100 align-items-center h-250px'>
          <img style={{width: '70px', objectFit: 'contain'}} src={loadingImg} alt='loading...' />
        </div>
      )
    const filterSelectedFlow = flowData && flowData.filter((row) => row.flowId === action?.flowId)

    return filterSelectedFlow?.length && filterSelectedFlow[0]?.stepsData ? (
      filterSelectedFlow[0]?.stepsData.map((_) => (
        <>
          <Col xs={12}>
            <Card className='text-center border rounded h-100' style={{background: '#f6f8fa'}}>
              <div
                className={`card-header border-0 px-4 ${
                  fielterCurrentStepAnswer(_.stepId)[0]?.isOpen &&
                  fielterCurrentStepAnswer(_.stepId)[0]?.stepId === _.stepId
                    ? 'replyOnStep'
                    : ''
                }`}
              >
                <h3 className='card-title fw-bold text-dark'>{_?.step_name || 'N/A'}</h3>
                <div className='card-toolbar'>
                  {fielterCurrentStepAnswer(_.stepId)[0] && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip data-bs-theme='dark'>View Result</Tooltip>}
                    >
                      <Button
                        className='btn-sm btn-light-warning me-2 btn-icon rounded-circle'
                        onClick={() =>
                          replyOnStep('', _.stepId, {
                            sources: fielterCurrentStepAnswer(_.stepId)[0]?.source,
                            answer: fielterCurrentStepAnswer(_.stepId)[0]?.answer,
                          })
                        }
                      >
                        <i className='bi bi-reply-fill fs-3'></i>
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip data-bs-theme='dark'>Play Step</Tooltip>}
                  >
                    <LoadingIconButton
                      setDisabled={false}
                      isLoading={
                        stepActions.isProcessingPlayStep && stepActions.stepId === _.stepId
                      }
                      buttonClasses='btn-sm btn-light-success me-2 btn-icon rounded-circle'
                      submitButtonAction={() =>
                        playSteps(
                          '',
                          {
                            collection_name: (_.libraries && _.libraries[0]) || '',
                            question: '',
                          },
                          _.stepId,
                          (_.prompts && _.prompts[0]) || ''
                        )
                      }
                    >
                      <KTIcon iconName='google-play' className='fs-3' />
                    </LoadingIconButton>
                  </OverlayTrigger>

                  {editStepAction.stepId === _.stepId ? (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip data-bs-theme='dark'>Stop Editing</Tooltip>}
                    >
                      <Button
                        variant='light'
                        className='btn-sm btn-icon btn-light-primary rounded-circle'
                        onClick={() =>
                          setEditStepAction({
                            ...editStepAction,
                            isEditEnabled: false,
                            stepId: '',
                          })
                        }
                      >
                        <KTIcon iconName='cross' className='fs-1' />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip data-bs-theme='dark'>Edit Step</Tooltip>}
                    >
                      <Button
                        variant='light'
                        className='btn-sm btn-icon btn-light-primary rounded-circle'
                        onClick={() =>
                          setEditStepAction({
                            ...editStepAction,
                            isEditEnabled: true,
                            stepId: _.stepId,
                          })
                        }
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip data-bs-theme='dark'>Detach step from current flow</Tooltip>}
                  >
                    <LoadingIconButton
                      buttonClasses='btn-sm btn-icon btn-light-danger rounded-circle ms-2'
                      submitButtonAction={() => {
                        deleteStep(_.stepId)
                        detachAction(
                          'detach_step',
                          _.stepId,
                          action?.flowId || filterSelectedFlow[0].flowId
                        )
                      }}
                      isLoading={
                        stepActions.isProcessingDetachStep && _.stepId === stepActions.detachingId
                      }
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </LoadingIconButton>
                  </OverlayTrigger>
                </div>
              </div>
              <Card.Body className='p-0'>
                <div
                  className={`position-absolute top-0 right-0 left-0 bottom-0 w-100 Drawer ${
                    fielterCurrentStepAnswer(_.stepId)[0]?.isOpen &&
                    fielterCurrentStepAnswer(_.stepId)[0]?.stepId === _.stepId
                      ? 'is-open'
                      : ''
                  }`}
                  style={{
                    opacity:
                      fielterCurrentStepAnswer(_.stepId)[0]?.isOpen &&
                      fielterCurrentStepAnswer(_.stepId)[0]?.stepId === _.stepId
                        ? '1'
                        : '0',
                    transition: 'all .3s',
                    visibility:
                      fielterCurrentStepAnswer(_.stepId)[0]?.isOpen &&
                      fielterCurrentStepAnswer(_.stepId)[0]?.stepId === _.stepId
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  <div
                    className='bg-white'
                    style={{
                      height: 'calc(100% - 66px)',
                      margin: '66px 5px auto',
                      borderRadius: '10px 10px 0 0',
                      overflowX: 'scroll',
                    }}
                  >
                    <div className='p-4 bg-light position-sticky sticky-top'>
                      <Button
                        variant='link'
                        className='position-absolute top-0'
                        style={{left: 0}}
                        onClick={() =>
                          replyOnStep('back', _.stepId, {
                            sources: fielterCurrentStepAnswer(_.stepId)[0]?.source,
                            answer: fielterCurrentStepAnswer(_.stepId)[0]?.answer,
                          })
                        }
                      >
                        <i className='ki-duotone ki-left fs-2'></i>Back
                      </Button>
                      <h5 className='card-title fw-bold text-dark m-0'>
                        {_.step_name.length > 20
                          ? _.step_name.substring(0, 17) + '...'
                          : _.step_name || 'NA'}
                      </h5>
                    </div>
                    <div className='p-5 text-start'>
                      <div>
                        <b>Source : </b> {fielterCurrentStepAnswer(_.stepId)[0]?.source}
                      </div>
                      <div className='mt-2'>
                        <b>Answer : </b> {fielterCurrentStepAnswer(_.stepId)[0]?.answer}
                      </div>
                    </div>
                  </div>
                </div>
                {((!_.actions?.length &&
                  !_.libraries?.length &&
                  !_.datasources?.length &&
                  !_.prompts?.length) ||
                  editStepAction.stepId === _.stepId) && (
                  <Row
                    md={
                      !_.actions?.length &&
                      !_.libraries?.length &&
                      !_.datasources?.length &&
                      !_.prompts?.length
                        ? 1
                        : 2
                    }
                    className='g-2 mb-4 px-4 '
                  >
                    {(_.actions?.length || _.prompts?.length) && (
                      <>
                        {(_.libraries?.length === undefined || _.libraries?.length < 1) && (
                          <Col
                            className='cursor-pointer'
                            onClick={() =>
                              setStepActions({
                                ...stepActions,
                                showModal: true,
                                stepId: _.stepId,
                                validationSchema: 1,
                                type: 'attach_library',
                              })
                            }
                          >
                            <p
                              className='fs-5 mb-0 text-primary d-flex align-items-center justify-content-center bg-light-primary border-primary rounded w-auto py-4 fs-7'
                              style={{borderStyle: 'dashed'}}
                            >
                              <KTIcon iconName='plus' className='fs-3 text-primary' />
                              Attach Library
                            </p>
                          </Col>
                        )}
                        <Col
                          className='cursor-pointer'
                          onClick={() =>
                            setStepActions({
                              ...stepActions,
                              showModal: true,
                              stepId: _.stepId,
                              validationSchema: 2,
                              type: 'attach_datasource',
                            })
                          }
                        >
                          <p
                            style={{borderStyle: 'dashed'}}
                            className='fs-5 mb-0 text-primary d-flex align-items-center justify-content-center bg-light-primary border-primary rounded w-auto py-4 fs-7'
                          >
                            <KTIcon iconName='plus' className='fs-3 text-primary' />
                            Attach Datasource
                          </p>
                        </Col>
                      </>
                    )}
                    {(_.prompts?.length === undefined || _.prompts?.length < 1) && (
                      <Col
                        className='cursor-pointer'
                        onClick={() =>
                          setStepActions({
                            ...stepActions,
                            showModal: true,
                            stepId: _.stepId,
                            validationSchema: 3,
                            type: 'attach_prompt',
                          })
                        }
                      >
                        <p
                          className='fs-5 mb-0 text-primary d-flex align-items-center justify-content-center bg-light-primary border-primary rounded w-auto py-4 fs-7'
                          style={{borderStyle: 'dashed'}}
                        >
                          <KTIcon iconName='plus' className='fs-3 text-primary' />
                          Attach Prompt
                        </p>
                      </Col>
                    )}
                    {(_.actions?.length === undefined || _.actions?.length < 1) && (
                      <Col
                        className='cursor-pointer'
                        onClick={() =>
                          setStepActions({
                            ...stepActions,
                            showModal: true,
                            stepId: _.stepId,
                            validationSchema: 4,
                            type: 'attach_action',
                          })
                        }
                      >
                        <p
                          className='fs-5 mb-0 text-primary d-flex align-items-center justify-content-center bg-light-primary border-primary rounded w-auto py-4 fs-7'
                          style={{borderStyle: 'dashed'}}
                        >
                          <KTIcon iconName='plus' className='fs-3 text-primary' />
                          Attach Action
                        </p>
                      </Col>
                    )}
                  </Row>
                )}
                <div
                  className={`pb-4 px-4 g-5 row m-0 overflow-scroll ${
                    editStepAction.stepId === _.stepId ? 'mh-300px' : 'mh-400px'
                  }`}
                >
                  {_.actions?.length &&
                    getFlowAttachments(
                      _.actions,
                      _.stepId,
                      <ActionIcon classes='w-50px' />,
                      'Action'
                    )}

                  {_.libraries?.length &&
                    getFlowAttachments(
                      _.libraries,
                      _.stepId,
                      <LibraryIcon classes='w-50px' />,
                      'Library'
                    )}
                  {_.datasources?.length &&
                    getFlowAttachments(
                      _.datasources,
                      _.stepId,
                      <i className='ki-duotone ki-abstract-26 fs-2x text-primary'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>,
                      'Datasource'
                    )}
                  {_.prompts?.length &&
                    getFlowAttachments(
                      _.prompts,
                      _.stepId,
                      <PromptIcon classes='w-50px' />,
                      'Prompt'
                    )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </>
      ))
    ) : (
      <Col className='col-12 text-center col-md-12'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
          alt='img'
          className='mw-200px'
        />
        <div className='fs-1 fw-bolder text-dark mb-4'>No Steps found.</div>
        <div className='fs-6 pb-5'>Start creating new Steps!</div>
      </Col>
    )
  }

  const initialValues: CreateStep = {
    stepName: '',
    actions: [],
    datasourceIds: [],
    libraryIds: [],
    promptIds: '',
    visibility: '',
    status: '',
    promptContent: '',
    promptOrigin: '',
  }

  const attachDataToSteps = async (type: string, stepId: string) => {
    setStepActions({...stepActions, isProcessingAttachStep: true})
    let url = ''
    let payload: {
      step_id?: string
      flow_id?: string
      datasource_id?: string
      library_id?: string
      prompt_id?: string
      action_id?: string
    } = {step_id: stepId}

    if (type === 'attach_step') {
      url = 'attach_step'
      payload.flow_id = action?.flowId
    } else {
      url = stepActions.type
    }
    switch (stepActions.type) {
      case 'attach_library':
        payload.library_id = values.libraryIds[0]
        break

      case 'attach_datasource':
        payload.datasource_id = values.datasourceIds[0]
        break

      case 'attach_prompt':
        if (values.promptContent !== values.promptOrigin) {
          await createNewPrompt('Custom Prompt', values.promptContent).then(
            (res) => (payload.prompt_id = res.data.prompt_id)
          )
        } else payload.prompt_id = values.promptIds
        break

      case 'attach_action':
        payload.action_id = values.actions[0]
        break
    }
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}`, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        toast.success(response.data.message)
        formik.resetForm()
        action?.flowId && fetchFlowById(action?.flowId)
        setStepActions({...stepActions, showModal: false, type: '', isProcessingAttachStep: false})
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingAttachStep: false})
        if (error.response) toast.error(`Error : ${error.response.data.error}`)
        else toast.error(`Unable to complete your request. Try again later.`)
      })
  }

  const createStep = async (values: CreateStep) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}create_step`, {
        step_name: values.stepName,
      })
      .then((response) => {
        attachDataToSteps('attach_step', response.data.step_id)
        setStepActions({...stepActions, isProcessingAttachStep: false, showModal: false, type: ''})
        toast.success(response.data.message)
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingAttachStep: false})
        toast.error(`Error : ${error.response.data.error}`)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createStepsValidationSchema[stepActions.validationSchema],
    onSubmit: (values) => {
      if (stepActions.type === 'create_step') {
        createStep(values)
      } else {
        attachDataToSteps('', stepActions.stepId || editStepAction.stepId)
      }
    },
  })

  const {values, errors, getFieldProps} = formik

  const setSelectedDatasourceIds = (id: string) => {
    let data
    if (values.datasourceIds.includes(id)) {
      data = values.datasourceIds!.filter((o) => {
        return o !== id
      })
    } else data = [...values.datasourceIds, id]
    formik.setFieldValue('datasourceIds', data)
  }

  const setSelectedLibraryIds = (id: string) => {
    let data
    if (values.libraryIds.includes(id)) {
      data = values.libraryIds!.filter((o) => {
        return o !== id
      })
    } else data = [...values.libraryIds, id]
    formik.setFieldValue('libraryIds', data)
  }

  const setSelectedPromptIds = (fieldName: string, value: any) => {
    value = JSON.parse(value)
    formik.setFieldValue('promptOrigin', value.prompt_template)
    formik.setFieldValue(
      fieldName,
      fieldName === 'promptIds' ? value.prompt_id : value.prompt_template
    )
  }

  const setSelectedActionIds = (id: string) => {
    let data
    if (values.actions.includes(id)) {
      data = values.actions!.filter((o) => {
        return o !== id
      })
    } else data = [...values.actions, id]
    formik.setFieldValue('actions', data)
  }

  const getAttachedView = () => {
    let view = <></>
    switch (stepActions.type) {
      case 'attach_library':
        view = (
          <div className='mh-400px overflow-scroll'>
            <LibraryListing
              type='selectable'
              selectedLibraryId={values.libraryIds}
              setSelectedLibraryIds={setSelectedLibraryIds}
            />
          </div>
        )
        break

      case 'attach_datasource':
        view = (
          <div className='mh-400px overflow-scroll'>
            <DataSourceListing
              type='selectable'
              selectedDatasourcesId={values.datasourceIds}
              setSelectedDatasourceIds={setSelectedDatasourceIds}
            />
          </div>
        )
        break

      case 'attach_prompt':
        view = (
          <>
            <PromptListing
              type='dropdown'
              selectedPromptId={values.promptIds}
              setSelectedPromptIds={setSelectedPromptIds}
            />
            <BootForm.Group controlId='validationFormikStepTitle' className='mt-5'>
              <BootForm.Label
                className='d-flex align-items-center fs-5 fw-semibold mb-2'
                for='promptContent'
              >
                <span className='required'>Prompt Content</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique app name'
                ></i>
              </BootForm.Label>
              <BootForm.Control
                as='textarea'
                rows={8}
                isInvalid={!!errors.promptContent}
                {...getFieldProps('promptContent')}
              />
              <BootForm.Control.Feedback type='invalid'>
                {errors.promptContent}
              </BootForm.Control.Feedback>
            </BootForm.Group>
          </>
        )
        break

      case 'attach_action':
        view = (
          <div className='mh-400px overflow-scroll'>
            <ActionLibraryListing
              type='selectable'
              selectedActionId={values.actions}
              setSelectedActionsIds={setSelectedActionIds}
            />
          </div>
        )
        break
    }
    return view
  }

  const playSteps = async (
    url: string,
    payload: {collection_name: string; question: string},
    stepId?: string,
    promptId: string = ''
  ) => {
    setStepActions({...stepActions, isProcessingPlayStep: true, stepId})
    await getPromptDataById(promptId)
      .then(async (res) => {
        payload.question = res.data.prompt_template
        payload.collection_name = payload.collection_name.replaceAll('-', '')
        await axios
          .post(`${process.env.REACT_APP_GET_ANSWER}`, JSON.stringify(payload), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            replyOnStep('', stepId, response.data)
            setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
          })
          .catch((error) => {
            setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
            toast.error(`Error : ${error.response.data.error}`)
          })
      })
      .catch((err) => console.log(err))
  }

  const playAllSteps = async () => {
    const stepsData = flowData?.filter((flow) => flow.flowId === action?.flowId)[0].stepsData
    const payload: {collection_name: string; question: string} = {
      collection_name: '',
      question: '',
    }
    // setStepActions({...stepActions, isProcessingPlayStep: true, stepId: item.stepId})
    stepsData &&
      Promise.all(
        stepsData.map((item) => {
          return new Promise<void>(async (resolve) => {
            item.prompts &&
              (await getPromptDataById(item.prompts[0])
                .then(async (res) => {
                  payload.question = res.data.prompt_template
                  payload.collection_name =
                    (item.libraries && item.libraries[0].replaceAll('-', '')) || ''
                  await axios
                    .post(`${process.env.REACT_APP_GET_ANSWER}`, JSON.stringify(payload), {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    })
                    .then((response) => {
                      replyOnStep('', item.stepId, response.data)
                      setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
                    })
                    .catch((error) => {
                      setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
                      toast.error(`Error : ${error.response.data.error}`)
                    })
                })
                .catch((err) => console.log(err)))
          })
        })
      )
  }

  const detachAction = async (url: string, stepId: string, dataId: string) => {
    setStepActions({
      ...stepActions,
      isProcessingDetachStep: true,
      detachingId: url === 'detach_step' ? stepId : dataId,
    })
    let payload: {
      step_id?: string
      flow_id?: string
      datasource_id?: string
      library_id?: string
      prompt_id?: string
      action_id?: string
    } = {step_id: stepId}

    switch (url) {
      case 'Action':
        url = `${process.env.REACT_APP_DETACH_ACTION}`
        payload.action_id = dataId
        break

      case 'Library':
        url = `${process.env.REACT_APP_DETACH_LIBRARY}`
        payload.library_id = dataId
        break

      case 'Prompt':
        url = `${process.env.REACT_APP_DETACH_PROMPT}`
        payload.prompt_id = dataId
        break

      case 'Datasource':
        url = `${process.env.REACT_APP_DETACH_DATASOURCE}`
        payload.datasource_id = dataId
        break

      case 'detach_step':
        url = `${process.env.REACT_APP_DETACH_STEP_FROM_FLOW}`
        payload.flow_id = dataId
        break
    }

    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}`, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        toast.success(response.data.message)
        formik.resetForm()
        setStepActions({
          ...stepActions,
          showModal: false,
          type: '',
          isProcessingDetachStep: false,
          detachingId: '',
        })
        action?.flowId && fetchFlowById(action?.flowId)
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingDetachStep: false, detachingId: ''})
        toast.error(`Error : ${error.response.data.error}`)
      })
  }

  const replyOnStep = (
    action: string = '',
    stepId: string = '',
    qa: {sources: string; answer: string},
    promptId?: string
  ) => {
    const currentStepIndex = showReplyWindow.findIndex((item) => item.stepId === stepId)
    if (currentStepIndex === -1) {
      setShowReplyWindow([
        ...showReplyWindow,
        {
          stepId,
          isOpen: action ? false : true,
          source: qa.sources,
          answer: qa.answer,
          promptId,
        },
      ])
    } else {
      const updatedTodo = {
        ...showReplyWindow[currentStepIndex],
        stepId,
        isOpen: action ? false : true,
        source: qa.sources,
        answer: qa.answer,
        promptId,
      }
      const newTodos = [
        ...showReplyWindow.slice(0, currentStepIndex),
        updatedTodo,
        ...showReplyWindow.slice(currentStepIndex + 1),
      ]
      setShowReplyWindow(newTodos)
    }
  }

  const fielterCurrentStepAnswer = (stepId: string) =>
    showReplyWindow?.filter((item) => item.stepId === stepId)

  useEffect(() => {
    if (action?.flowData?.visibility !== values.visibility)
      formik.setFieldValue('visibility', action?.flowData?.visibility)

    if (action?.flowData?.status !== values.status)
      formik.setFieldValue('status', action?.flowData?.status)
  }, [action?.flowData?.visibility, action?.flowData?.status])

  return (
    <>
      {action?.type === 'view' ? (
        <div className={`card`}>
          <div className='card-header bg-white'>
            <div className='d-flex align-items-center'>
              <button className='btn btn-link' onClick={() => setAction({...action, type: ''})}>
                <i className='ki-duotone ki-left fs-2'></i>Back
              </button>
            </div>
            <div className='d-flex align-items-center position-relative'>
              <Button
                className='btn btn-sm btn-light-primary'
                onClick={() =>
                  setStepActions({...stepActions, showModal: true, type: 'create_step'})
                }
              >
                <KTIcon iconName='plus' className='fs-3' />
                Create Step
              </Button>

              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip data-bs-theme='dark'>{showCopied ? 'copied' : 'Copy Webhook'}</Tooltip>
                }
              >
                <Button
                  variant='light'
                  className='btn-icon btn-light-primary rounded-circle btn-light ms-4'
                  onClick={() =>
                    handleCopy(`${window.location.href}/${action.flowId}?prompt=Enter your prompt`)
                  }
                >
                  <i className='bi bi-link-45deg fs-2x'></i>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip data-bs-theme='dark'>Play Flow</Tooltip>}
              >
                <Button
                  className='btn btn-danger ms-4 btn-icon rounded-circle'
                  onClick={() => playAllSteps()}
                >
                  <KTIcon iconName='google-play' className='fs-2' />
                </Button>
              </OverlayTrigger>
            </div>
          </div>

          <div className='card-header border-0 pt-5 row'>
            <h3 className='card-title align-items-start flex-column col'>
              <span className='card-label fw-bold fs-3 mb-1'>{action.flowData?.name}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {action.flowData?.description}
              </span>
            </h3>
            <div className='toolbar d-flex col-auto'>
              <div
                className='ms-5 bg-light rounded p-1 px-4 border-secondary'
                style={{borderStyle: 'dashed'}}
              >
                <label className='form-label fw-bold'>Visibility:</label>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-25px w-45px'
                    type='checkbox'
                    checked={values?.visibility === 'Public' ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue('visibility', e.target.checked ? 'Public' : 'Private')
                      updateFlowVisibility(e.target.checked ? 'Public' : 'Private')
                    }}
                  />
                  <label className='form-check-label'>
                    {values.visibility ? 'Public' : 'Private'}
                  </label>
                </div>
              </div>

              <div
                className='ms-5 bg-light rounded p-1 px-4 border-secondary'
                style={{borderStyle: 'dashed'}}
              >
                <label className='form-label fw-bold'>Status:</label>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-25px w-45px'
                    type='checkbox'
                    checked={values?.status === 'Publish' ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue('status', e.target.checked ? 'Publish' : 'Unpublish')
                      updateFlowStatus(e.target.checked ? 'Publish' : 'Unpublish')
                    }}
                  />
                  <label className='form-check-label'>
                    {values.status ? 'Publish' : 'Unpublish'}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Row md={3} className='g-6 mb-4 m-0 p-6'>
            {flowData && getSteps()}
          </Row>
        </div>
      ) : (
        <div className={`card`}>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Flow Statistics</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                Over {flowData?.length} flows
              </span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <Button
                className='btn-sm btn-light-primary'
                onClick={() => setCreateFlowActions({...createFlowActions, isShowModal: true})}
              >
                <KTIcon iconName='plus' className='fs-3' />
                New Flow
              </Button>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>About Flow</th>
                    <th className='min-w-140px'># Steps</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {flowData &&
                    flowData.map((item) => (
                      <tr>
                        <td
                          onClick={() => {
                            setAction({type: 'view', flowData: item, flowId: item.flowId})
                            fetchStepsInfo(item.steps, item.flowId, true)
                          }}
                        >
                          <div className='d-flex align-items-center '>
                            <div className='d-flex justify-content-start flex-column'>
                              <Button
                                variant='link'
                                className='text-dark fw-bold text-hover-primary fs-6 p-0 text-start'
                              >
                                {item.name}
                              </Button>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.description}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label bg-light-success fs-3 text-success'>
                              {item.steps?.length}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                setAction({type: 'view', flowData: item, flowId: item.flowId})
                                fetchStepsInfo(item.steps, item.flowId, true)
                              }}
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </button>
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() =>
                                setAction({type: 'edit', flowData: item, flowId: item.flowId})
                              }
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button> */}
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              onClick={() => {
                                setDeleteModal(true)
                                setAction({type: 'delete', flowData: item, flowId: item.flowId})
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <CreateFlow
        createFlowActions={createFlowActions}
        setCreateFlowActions={setCreateFlowActions}
        fetchFlowById={fetchFlowById}
      />
      <Modal
        show={stepActions?.showModal}
        dialogClassName={`${stepActions.type === 'create_step' ? 'mw-500px' : 'mw-900px'}`}
        centered
      >
        <Modal.Header className='p-5 border-0'>
          <Modal.Title className='m-auto' style={{textTransform: 'capitalize'}}>
            {stepActions.type.split('_').join(' ')}{' '}
            {stepActions.type !== 'create_step' && 'to Step'}
          </Modal.Title>
          <button
            type='button'
            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
            onClick={() => {
              setStepActions({...stepActions, showModal: false})
              formik.resetForm()
            }}
          >
            <KTIcon iconName='cross' className='fs-2' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form className='mx-auto w-100 pt-5 pb-0'>
              {stepActions.type === 'create_step' ? (
                <div className='mb-10'>
                  <BootForm.Group controlId='validationFormikStepTitle'>
                    <BootForm.Label
                      className='d-flex align-items-center fs-5 fw-semibold mb-2'
                      for='stepName'
                    >
                      <span className='required'>Step Title</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Specify your unique app name'
                      ></i>
                    </BootForm.Label>
                    <BootForm.Control
                      type='text'
                      isInvalid={!!errors.stepName}
                      {...getFieldProps('stepName')}
                    />
                    <BootForm.Control.Feedback type='invalid'>
                      {errors.stepName}
                    </BootForm.Control.Feedback>
                  </BootForm.Group>
                </div>
              ) : (
                getAttachedView()
              )}

              <div className='d-flex pt-5 mt-5 justify-content-end border-0'>
                <LoadingButton
                  isLoading={stepActions.isProcessingAttachStep}
                  submitButtonAction={() => {
                    setStepActions({...stepActions, isProcessingAttachStep: true})
                    formik.submitForm()
                  }}
                  loadingButtonTitle={`${
                    stepActions.type === 'create_step' ? 'Creating...' : 'Attaching...'
                  }`}
                  buttonClasses={'btn-sm me-4'}
                >
                  {stepActions.type === 'create_step' ? 'Create' : 'Attach'}{' '}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                </LoadingButton>
              </div>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
      <EditPrompt promptData={editPrompt} editPromptAction={setEditPrompt} />

      <ViewActionLibrary
        viewActionLibrary={viewActionLibrary}
        setViewActionLibrary={setViewActionLibrary}
      />

      <Modal show={showDeleteModal} centered>
        <Modal.Header className='p-4 border-0'>
          <h3 className='mb-0 m-auto'>Delete Flow</h3>
          <button
            type='button'
            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
            onClick={() => setDeleteModal(false)}
          >
            <KTIcon iconName='cross' className='fs-2' />
          </button>
        </Modal.Header>

        <Modal.Body>
          <span>Are you Sure you want to delete Flow?</span>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteFlow()} size='sm'>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Flow
