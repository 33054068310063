import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import PromptLibraryContainer from './PromptLibraryContainer'

function DataSource() {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <PromptLibraryContainer />
    </>
  )
}

export default DataSource
