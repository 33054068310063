import * as React from 'react'

interface Props {
  classes: string
}

const LibraryIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 493.181 493.181'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <linearGradient
        id='a'
        x1={-21.22}
        x2={-21.22}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopOpacity={1} stopColor='#06a6f2' offset={0.8301158301158301} />
        <stop stopOpacity={1} stopColor='#07a5f0' offset={1} />
        <stop stopOpacity={1} stopColor='#42445a' offset={1} />
      </linearGradient>
      <path
        d='M487.386 454.117 367.298 5.925a8 8 0 0 0-9.776-5.656L249.314 29.261a7.81 7.81 0 0 0-3.792 2.504V14.589a8 8 0 0 0-8-8h-224a8 8 0 0 0-8 8v464a8 8 0 0 0 8 8h224a8 8 0 0 0 8-8V46.013l118.224 441.24a8 8 0 0 0 7.728 5.928 8.285 8.285 0 0 0 2.048-.272l108.184-28.992a8 8 0 0 0 5.68-9.8zM21.522 22.589h96v448h-96v-448zm208 448h-96v-448h96v448zm147.608 4.8L261.186 42.645l92.728-24.856 115.944 432.744-92.728 24.856z'
        style={{
          fill: 'url(#a)',
        }}
        fill=''
      />
      <linearGradient
        id='b'
        x1={-43.353}
        x2={-43.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M93.522 54.589h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z'
        style={{
          fill: 'url(#b)',
        }}
        fill=''
      />
      <linearGradient
        id='c'
        x1={-43.353}
        x2={-43.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M93.522 86.589h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z'
        style={{
          fill: 'url(#c)',
        }}
        fill=''
      />
      <linearGradient
        id='d'
        x1={-29.353}
        x2={-29.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M157.522 70.589h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16z'
        style={{
          fill: 'url(#d)',
        }}
        fill=''
      />
      <linearGradient
        id='e'
        x1={-29.353}
        x2={-29.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M157.522 102.589h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16z'
        style={{
          fill: 'url(#e)',
        }}
        fill=''
      />
      <linearGradient
        id='f'
        x1={-12.302}
        x2={-12.302}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M343.122 70.373a8 8 0 1 0-4.143-15.448l-46.4 12.416a8 8 0 0 0 4.063 15.475l.073-.019 46.407-12.424z'
        style={{
          fill: 'url(#f)',
        }}
        fill=''
      />
      <linearGradient
        id='g'
        x1={-11.271}
        x2={-11.271}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='m347.29 85.829-46.36 12.424a8 8 0 0 0 4.063 15.475l.073-.019 46.4-12.424a8.001 8.001 0 0 0-4.144-15.456h-.032z'
        style={{
          fill: 'url(#g)',
        }}
        fill=''
      />
      <linearGradient
        id='h'
        x1={-1.44}
        x2={-1.44}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M381.682 407.621a8.1 8.1 0 0 0 2.072-.272l46.36-12.424a8 8 0 0 0-4.136-15.456l-46.4 12.424a8 8 0 0 0 2.072 15.728h.032z'
        style={{
          fill: 'url(#h)',
        }}
        fill=''
      />
      <linearGradient
        id='i'
        x1={-0.397}
        x2={-0.397}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M387.922 422.805a8 8 0 0 0 2.072 15.728c.7.001 1.397-.093 2.072-.28l46.4-12.416a8 8 0 0 0-4.136-15.456l-46.408 12.424z'
        style={{
          fill: 'url(#i)',
        }}
        fill=''
      />
      <linearGradient
        id='j'
        x1={-29.353}
        x2={-29.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M157.522 406.589h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16z'
        style={{
          fill: 'url(#j)',
        }}
        fill=''
      />
      <linearGradient
        id='k'
        x1={-29.353}
        x2={-29.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M157.522 438.589h48a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16z'
        style={{
          fill: 'url(#k)',
        }}
        fill=''
      />
      <linearGradient
        id='l'
        x1={-43.353}
        x2={-43.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M93.522 390.589h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z'
        style={{
          fill: 'url(#l)',
        }}
        fill=''
      />
      <linearGradient
        id='m'
        x1={-43.353}
        x2={-43.353}
        y1={561.844}
        y2={627.193}
        gradientTransform='matrix(8 0 0 -8 416.347 5000.302)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset={0}
          style={{
            stopColor: '#006DF0',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#00E7F0',
          }}
        />
      </linearGradient>
      <path
        d='M93.522 422.589h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z'
        style={{
          fill: 'url(#m)',
        }}
        fill=''
      />
    </g>
  </svg>
)
export default LibraryIcon
