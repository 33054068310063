import * as React from 'react'

interface Props {
  classes: string
}

const EmailIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <path d='M64 64h384v384H64z' style={{}} fill='#eceff1' data-original='#eceff1' className='' />
      <path d='M256 296.384 448 448V148.672z' style={{}} fill='#cfd8dc' data-original='#cfd8dc' />
      <path
        d='M464 64h-16L256 215.616 64 64H48C21.504 64 0 85.504 0 112v288c0 26.496 21.504 48 48 48h16V148.672l192 147.68L448 148.64V448h16c26.496 0 48-21.504 48-48V112c0-26.496-21.504-48-48-48z'
        style={{}}
        fill='#f44336'
        data-original='#f44336'
        className=''
      />
    </g>
  </svg>
)
export default EmailIcon
