import {initializeApp} from 'firebase/app'
import {getAuth, GoogleAuthProvider, FacebookAuthProvider} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCUQp4q9JlJv-OLFYeDSwRytQduudKPpIY",
  authDomain: "workflow-383705.firebaseapp.com",
  projectId: "workflow-383705",
  storageBucket: "workflow-383705.appspot.com",
  messagingSenderId: "771377073127",
  appId: "1:771377073127:web:bbdaed159c85e0aaa44b42",
  measurementId: "G-9JBX2GBHG7",
};

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

const provider = new GoogleAuthProvider()
const FBprovider = new FacebookAuthProvider()

export {auth, provider, FBprovider}


