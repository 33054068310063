import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import FolderUploadContainer from './FolderUploadContainer'

function Folder() {
  const intl = useIntl()

  return (
    <>
      <FolderUploadContainer />
    </>
  )
}

export default Folder
