import React, {useEffect, useState} from 'react'
import '../../App.css'
import axios from 'axios'

import {AiOutlineEye} from 'react-icons/ai'
import {BsFiles, BsTrash} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import loadingimg from '../../../_metronic/assets/loading.gif'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Card, Col, Image, Row} from 'react-bootstrap'
import LibraryIcon from '../../../_metronic/assets/svgIcon/LibraryIcon'

function Library() {
  const [libraryName, setLibraryName] = useState('')
  const [searchLibraryName, setSearchLibraryName] = useState('')
  const [searchDataSourceName, setsearchDataSourceName] = useState('')
  const [librarydata, setgetlibrarydata] = useState()
  const [dataSources, setdataSources] = useState()
  const [libraryidvalue, setlibraryidvalue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [library, setLibrary] = useState([])
  const [loading, setLoading] = useState(false)

  const handleCheck = (event, ind) => {
    const searchDataSource = dataSources.filter((item) =>
      item.name.toLowerCase().includes(searchDataSourceName.toLowerCase())
    )

    const selectedDataSource = searchDataSource[ind]
    const indexInDataSources = dataSources.indexOf(selectedDataSource)

    setdataSources((prevDataSources) => {
      const updatedDataSources = [...prevDataSources]
      updatedDataSources[indexInDataSources] = {
        ...selectedDataSource,
        isChecked: event.target.checked,
      }
      return updatedDataSources
    })
  }

  const newData = dataSources?.filter((row) => row.isChecked === true).map((key) => key.key)

  const formData = new FormData()
  formData.append('library_name', libraryName)
  newData?.forEach((dsId) => formData.append('datasource_ids', dsId.trim()))

  const attachLibraryToFlask = async (library_id) => {
    var data = JSON.stringify({
      library_id,
    })

    await axios({
      method: 'post',
      url: process.env.REACT_APP_CREATE_LIBRARY,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const createLibrary = async () => {
    setLoading(true)

    const result = await axios.post(
      `${process.env.REACT_APP_DATASOURCE_LIST}/create_library`,
      formData
    )
    if (result?.status == 200) {
      attachLibraryToFlask(result.data.library_id)
      setIsLoading(false)
      setLibraryName('')
      setdataSources(
        dataSources !== undefined &&
          dataSources.map((key, index) => {
            return {...key, isChecked: false}
          })
      )

      getAllLibraryData()
    }
  }

  const getAllLibraryData = async () => {
    const result = await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/libraries`)
    setgetlibrarydata(
      Object.entries(result.data).map((key) => {
        return {...key[1], key: key[0]}
      })
    )
    setIsLoading(false)
  }

  const getAllDataSources = async () => {
    const result = await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/datasources`)
    setdataSources(
      Object.entries(result.data).map((key) => {
        return {...key[1], key: key[0], isChecked: false}
      })
    )
  }

  const organizeFiles = (filePaths) => {
    const root = {name: 'root', children: []}

    filePaths.forEach((filePath) => {
      const pathParts = filePath.split('/')

      let currentNode = root

      pathParts.forEach((part, index) => {
        if (!currentNode.children) {
          currentNode.children = []
        }

        const isLastPart = index === pathParts.length - 1
        const isFolder = !isLastPart

        let node = currentNode.children.find((n) => n.name === part)
        if (!node) {
          node = {name: part, children: []}
          currentNode.children.push(node)
        }

        if (isFolder) {
          currentNode = node
        } else {
          node.filePath = filePath
        }
      })
    })
    if (root.children.length === 1 && root.children[0].name === 'uploaded_folders') {
      return root.children[0].children
    }

    return root.children
  }

  const getlibrarybyid = async (id) => {
    await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/libraries/${id}`).then((response) => {
      setlibraryidvalue(response.data)
    })
  }

  useEffect(() => {
    if (libraryidvalue) {
      files()
    }
  }, [libraryidvalue])

  const files = () => {
    const tempdata = libraryidvalue
    if (tempdata) {
      const file_paths = JSON.parse(tempdata.file_paths)
      const result = organizeFiles(file_paths).filter((item) => item.name !== '')
      console.log('=====>', result)
      setLibrary(result)
    }
  }

  const [previousFolders, setPreviousFolders] = useState([])
  const [currentPath, setCurrentPath] = useState('uploaded_folders')
  const [previousPaths, setPreviousPaths] = useState([])

  const handleFolder = (name) => {
    const file_paths = JSON.parse(libraryidvalue.file_paths)

    const path = file_paths?.find((item) => item.includes(name))

    if (!path) {
      console.error(`Path not found for folder: ${name}`)
      return
    }
    const parts = path.split('/')
    const indexfolder = parts.indexOf(name)
    const targetParts = parts.slice(0, indexfolder + 1)
    const targetPath = targetParts.join('/')
    const newCurrentPath = targetPath

    setPreviousPaths([...previousPaths, currentPath])
    setCurrentPath(newCurrentPath)
    localStorage.setItem('filePath', newCurrentPath)
    const selectedFolder = library?.filter((row) => row.name === name)
    const tempData1 = selectedFolder?.map((row) => row.children)
    const child = tempData1[0]?.filter((row) => row?.name !== '')

    setLibrary(child)
    setPreviousFolders([...previousFolders, library])

    return newCurrentPath
  }
  const goBack = () => {
    if (previousPaths.length > 0) {
      const prevPath = previousPaths[previousPaths.length - 1]
      setCurrentPath(prevPath)
      setPreviousPaths(previousPaths.slice(0, previousPaths.length - 1))
      const lastElement = previousFolders.pop()
      setLibrary(lastElement)
    }
  }

  useEffect(() => {
    getAllLibraryData()
    getAllDataSources()
  }, [])

  const searchLibrary = librarydata?.filter((item) =>
    item.name.toLowerCase().includes(searchLibraryName.toLocaleLowerCase())
  )

  const searchDataSource = dataSources?.filter((item) =>
    item.name.toLowerCase().includes(searchDataSourceName.toLocaleLowerCase())
  )

  return (
    <div>
      <div className='card card-flush'>
        <div className='card-header p-10 pb-0 bg-white'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-circle me-5'>
              <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed'>
                <i className='ki-duotone ki-abstract-47 fs-2x text-primary'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <h2 className='mb-1'>Library</h2>
              <div className='text-muted fw-bold'></div>
            </div>
          </div>
        </div>
        <div className='card-header pt-8'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search DataSource'
                onChange={(e) => setSearchLibraryName(e.target.value)}
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              <button
                id='kt_file_manager_new_folder'
                data-bs-target='#kt_modal_create_app'
                data-bs-toggle='modal'
                type='button'
                className='btn btn-flex  btn-light-primary'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Create New Library
              </button>
            </div>

            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            ></div>
          </div>
        </div>
        <div
          className='dataTables_wrapper dt-bootstrap4 mt-6 no-footer dataSource-table'
          id='kt_file_manager_list_wrapper'
        >
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <img
                style={{height: '100px', width: '100px', objectFit: 'cover'}}
                src={loadingimg}
                alt='loading...'
              />
            </div>
          ) : (
            <Row xs md={3} className='g-5 mb-5'>
              {searchLibrary.length ? (
                searchLibrary.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer'>
                      <Card.Body className='p-3'>
                        <div class='row no-gutters'>
                          <div class='col-md-auto'>
                            <div class='symbol symbol-75px m-0 w-100'>
                              <div className='symbol-label bg-light-primary p-5'>
                                <LibraryIcon classes='w-75px' />
                              </div>
                            </div>
                          </div>
                          <div class='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                            <p className='card-title pt-1'>
                              {_?.name.length > 12 ? _?.name.slice(0, 12) + '...' : _?.name}
                            </p>
                            <div className='d-flex gap-2 col-12'>
                              <button
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                data-kt-menu-placement='bottom-end'
                                data-bs-target='#kt_modal_view_app'
                                data-bs-toggle='modal'
                                onClick={() => getlibrarybyid(_.key)}
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                              </button>
                              <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <KTIcon iconName='pencil' className='fs-3' />
                              </button>
                              <button className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'>
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className='col-12 col-md-12 text-center'>
                  <Image
                    src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                    className='mw-200px'
                  />
                  <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                  <div className='fs-6 pb-5'>
                    Start creating new folders or uploading a new file!
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>
      {/* modal */}
      <div class='modal fade' id='kt_modal_create_app' tabindex='-1' aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div class='modal-dialog modal-dialog-centered mw-900px'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h2></h2>

              <h2>Create Library</h2>

              <div class='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <i class='ki-duotone ki-cross fs-1'>
                  <span class='path1'></span>
                  <span class='path2'></span>
                </i>
              </div>
            </div>

            <div class='modal-body py-lg-10 px-lg-10'>
              <div
                class='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                {/*begin::Aside*/}

                <div class='flex-row-fluid py-lg-5 px-lg-15'>
                  {/*begin::Form*/}
                  <form class='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                    {/*begin::Step 1*/}
                    <div class='current' data-kt-stepper-element='content'>
                      <div class='w-100'>
                        {/*begin::Input group*/}
                        <div class='d-flex fv-row mb-10 library-name-modal'>
                          {/*begin::Label*/}
                          <label class='d-flex align-items-center fs-5 fw-semibold mb-2 library-text-modal'>
                            <span class='required'>Library Name</span>
                            <span
                              class='ms-1'
                              data-bs-toggle='tooltip'
                              title='Specify your unique app name'
                            ></span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            class='form-control form-control-lg form-control-solid'
                            name='name'
                            value={libraryName}
                            placeholder=''
                            onChange={(e) => setLibraryName(e.target.value)}
                          />
                          {/*end::Input*/}
                        </div>
                        <div class='fv-row mb-10 library-search-modal'>
                          {/*begin::Label*/}
                          <label class='d-flex align-items-center fs-5 fw-semibold mb-2 library-text-modal'>
                            <span class='required'>Search</span>
                            <span
                              class='ms-1'
                              data-bs-toggle='tooltip'
                              title='Specify your unique app name'
                            ></span>
                          </label>

                          <div class='d-flex align-items-center position-relative my-1'>
                            <i class='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                              <span class='path1'></span>
                              <span class='path2'></span>
                            </i>
                            <input
                              type='text'
                              data-kt-filemanager-table-filter='search'
                              class='form-control form-control-solid ps-15'
                              placeholder='Search DataSources'
                              onChange={(e) => setsearchDataSourceName(e.target.value)}
                            />
                          </div>
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div class='fv-row'>
                          {/*begin::Label*/}
                          <label class='d-flex align-items-center fs-5 fw-semibold mb-4'>
                            <span class='required'>DataSources</span>
                            <span
                              class='ms-1'
                              data-bs-toggle='tooltip'
                              title='Select your app category'
                            >
                              <i class='ki-duotone ki-information-5 text-gray-500 fs-6'>
                                <span class='path1'></span>
                                <span class='path2'></span>
                                <span class='path3'></span>
                              </i>
                            </span>
                          </label>

                          <div class='fv-row ScrollStyle'>
                            {searchDataSource !== undefined
                              ? searchDataSource?.map((item, index) => {
                                  return (
                                    <label
                                      class='d-flex flex-stack mb-5 cursor-pointer'
                                      key={index}
                                    >
                                      <span class='d-flex align-items-center me-2'>
                                        <span class='symbol symbol-50px me-6'>
                                          <span class='symbol-label bg-light-primary'>
                                            <BsFiles className='databaseIcon' />
                                          </span>
                                        </span>

                                        <span class='d-flex flex-column'>
                                          <span class='fw-bold fs-6'>{item.name}</span>
                                        </span>
                                      </span>

                                      <span class='form-check form-check-custom form-check-solid'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          value={item.key}
                                          checked={item.isChecked}
                                          onChange={(event) => handleCheck(event, index)}
                                        />
                                      </span>
                                    </label>
                                  )
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='d-flex flex-stack pt-10'>
                      <div></div>
                      <div>
                        {libraryName ? (
                          loading ? (
                            <button
                              className='btn btn-primary file-input__label mb-4 ml-4 mx-4'
                              type='button'
                              syle={{border: 'none', display: 'inline-block', height: '10px'}}
                              disabled
                            >
                              Creating...
                              <span
                                class='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              ></span>
                            </button>
                          ) : (
                            <a
                              className='file-input__label mb-4 ml-4 mx-4'
                              style={{display: 'inline-block'}}
                              onClick={() => createLibrary()}
                              data-bs-dismiss='modal'
                            >
                              Create Library
                            </a>
                          )
                        ) : null}

                        {/* <button
                          type='button'
                          class='btn btn-lg btn-primary'
                          data-kt-stepper-action='next'
                          onClick={createLibrary}
                        >
                          Create Library
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* view Modal */}
      <div class='modal fade' id='kt_modal_view_app' tabindex='-1' aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div class='modal-dialog modal-dialog-centered mw-900px'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h2></h2>

              <h2>View Library</h2>

              <div class='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <i class='ki-duotone ki-cross fs-1'>
                  <span class='path1'></span>
                  <span class='path2'></span>
                </i>
              </div>
            </div>

            <div class='modal-body py-lg-10 px-lg-10'>
              <div
                class='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                {/*begin::Aside*/}

                <div class='flex-row-fluid py-lg-5 px-lg-15'>
                  {/*begin::Form*/}
                  <form class='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                    {/*begin::Step 1*/}
                    <div class='current' data-kt-stepper-element='content'>
                      <div class='w-100'>
                        {/*begin::Input group*/}
                        <div class='d-flex fv-row mb-10 library-name-modal'>
                          {/*begin::Label*/}
                          <label class='d-flex align-items-center fs-5 fw-semibold mb-2 library-text-modal'>
                            <span class='required'>Library Name</span>
                            <span
                              class='ms-1'
                              data-bs-toggle='tooltip'
                              title='Specify your unique app name'
                            ></span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type='text'
                            class='form-control form-control-lg form-control-solid'
                            name='name'
                            value={libraryidvalue?.name}
                          />
                          {/*end::Input*/}
                        </div>
                        <div class='fv-row mb-10 library-search-modal'></div>

                        {/*begin::Input group*/}
                        <div class='fv-row'>
                          {/*begin::Label*/}
                          <label class='d-flex align-items-center fs-5 fw-semibold mb-4'>
                            <span class='required'>Files and Folders</span>
                            <span
                              class='ms-1'
                              data-bs-toggle='tooltip'
                              title='Select your app category'
                            >
                              <i class='ki-duotone ki-information-5 text-gray-500 fs-6'>
                                <span class='path1'></span>
                                <span class='path2'></span>
                                <span class='path3'></span>
                              </i>
                            </span>
                          </label>

                          {currentPath === 'uploaded_folders' ? null : (
                            <a className='btn btn-link mb-3' onClick={goBack}>
                              <i className='ki-duotone ki-left fs-2'></i>Back{' '}
                            </a>
                          )}
                          <Row xs md={4} className='g-6 mb-5 flex-row flex-nowrap overflow-scroll'>
                            {library.length ? (
                              library.map((_) => (
                                <Col>
                                  <Card className='text-center border border-secondary cursor-pointer'>
                                    {!_?.name.match(/\.[^\/]+$/) && (
                                      <span
                                        className='badge badge-light-primary position-absolute'
                                        style={{top: 5, right: 5}}
                                      >
                                        {_?.children.length} Item{_?.children.length > 1 && 's'}
                                      </span>
                                    )}
                                    <Card.Img
                                      variant='top'
                                      className='m-auto pt-5'
                                      style={{width: 80}}
                                      src={
                                        _?.name.match(/\.[^\/]+$/)
                                          ? toAbsoluteUrl('/media/file.png')
                                          : toAbsoluteUrl('/media/folder/folder.png')
                                      }
                                      onClick={
                                        _?.name.match(/\.[^\/]+$/)
                                          ? null
                                          : () => handleFolder(_?.name)
                                      }
                                    />
                                    <Card.Body className='p-3 pt-0'>
                                      <p className='card-title text-truncate'>{_?.name}</p>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))
                            ) : (
                              <Col className='col-12 col-md-12 text-center'>
                                <Image
                                  src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                                  className='mw-200px'
                                />
                                <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                                <div className='fs-6 pb-5'>
                                  Start creating new folders or uploading a new file!
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Library
