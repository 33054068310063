import React, {useEffect, useState} from 'react'
import '../../App.css'
import axios from 'axios'
import {BsFillLayersFill} from 'react-icons/bs'
import loadingImg from '../../../_metronic/assets/loading.gif'
import {Button, Dropdown} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import PromptIcon from '../../../_metronic/assets/svgIcon/PromptIcon'

interface Props {
  type?: string
  selectedPromptId?: string
  setSelectedPromptIds: any
}

interface Prompt {
  prompt: string
  prompt_id: string
  prompt_template: string
}
type CustomToggleProps = {
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
}

function PromptListing({type, selectedPromptId, setSelectedPromptIds}: Props) {
  const [promptData, setPromptData] = useState<Prompt[]>([])
  const [viewdata, setViewData] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [promptTitle, setPromptTitle] = useState('')
  const [promptDescription, setPromptDiscription] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [editPromptModal, setEditPromptModal] = useState()
  const [editpromptTitle, seteditpromptTitle] = useState()
  const [editpromptTemplete, seteditpromptTemplete] = useState()
  const [editprompt, setEditPrompt] = useState()
  const [editpromptid, setEditPromptid] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const getPromptData = async () => {
    await axios
      .get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/get_all_prompts`)
      .then((response) => {
        setPromptData(response.data)
      })
    setIsLoading(false)
  }

  useEffect(() => {
    getPromptData()
  }, [])

  const filterData = promptData.filter((item) =>
    item.prompt.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  )

  const theChosenFruit = () => {
    const chosenFruit = filterData.filter((f) => selectedPromptId === f.prompt_id)
    return chosenFruit.length ? (
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-70px me-4'>
          <span className='symbol-label bg-light-primary fs-2 text-primary'>
            <PromptIcon classes='w-30px' />
          </span>
        </div>
        <div className='d-flex justify-content-start flex-column'>
          <button className='text-dark fw-bold text-hover-primary fs-6 btn p-0 text-start'>
            {chosenFruit[0]?.prompt}
          </button>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>
            {chosenFruit[0]?.prompt_template}
          </span>
        </div>
      </div>
    ) : (
      'Select a Prompt'
    )
  }

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
      <a
        className='w-100 d-flex align-items-center justify-content-between border text-black p-5 rounded'
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          if (props.onClick) props.onClick(e)
        }}
      >
        {props.children}
        <span style={{paddingLeft: '5px'}}>
          <KTIcon iconName='arrow-down' className='fs-2' />
        </span>
      </a>
    )
  )

  return (
    <>
      <div className='card card-flush w-100'>
        <div className={`card-body ${type === 'selectable' || (type === 'dropdown' && 'p-0')}`}>
          {isLoading ? (
            <div className='d-flex justify-content-center w-100 align-items-center h-150px'>
              <img
                style={{width: '70px', objectFit: 'contain'}}
                src={loadingImg}
                alt='loading...'
              />
            </div>
          ) : (
            <>
              {type === 'dropdown' ? (
                <Dropdown
                  className='position-relative'
                  onSelect={(e: any) => {
                    setSelectedPromptIds('promptIds', e)
                    setSelectedPromptIds('promptContent', e)
                  }}
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id='dropdown-custom-components'
                    style={{borderStyle: 'dashed'}}
                    className=' position-relative'
                  >
                    {theChosenFruit()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='mh-300px overflow-scroll w-100'>
                    {filterData.map((_) => {
                      return (
                        <Dropdown.Item key={_.prompt_id} eventKey={JSON.stringify(_)}>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-4'>
                              <span className='symbol-label bg-light-primary fs-3 text-primary'>
                                <BsFillLayersFill />
                              </span>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <button className='text-dark fw-bold text-hover-primary fs-6 btn p-0 text-start'>
                                {_.prompt}
                              </button>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {_.prompt_template}
                              </span>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>About Prompt</th>
                        <th className='min-w-100px text-end'>Select</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {filterData?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px me-4'>
                                  <span className='symbol-label bg-light-primary fs-3 text-primary'>
                                    <BsFillLayersFill />
                                  </span>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <button className='text-dark fw-bold text-hover-primary fs-6 btn p-0 text-start'>
                                    {item.prompt}
                                  </button>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {item.prompt_template}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className='form-check form-check-custom form-check-solid justify-content-end'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value={item?.prompt_id}
                                  onChange={() => setSelectedPromptIds(item?.prompt_id)}
                                  checked={selectedPromptId?.includes(item?.prompt_id)}
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PromptListing
