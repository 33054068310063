import { CREATE_SESSION, DESTROY_SESSION } from "./AuthActionTypes";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  userInfo: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case DESTROY_SESSION:
      return { ...state, isLoggedIn: false };
    case CREATE_SESSION: {
      return {
        ...state,
        isLoading: true,
        isLoggedIn: true,
        userInfo: action.user,
      };
    }
    default:
      return state;
  }
};
