import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import ActionLibraryContainer from './ActionLibraryContainer'

function ActionLibrary() {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <ActionLibraryContainer />
    </>
  )
}

export default ActionLibrary
