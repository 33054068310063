import {useEffect, useState} from 'react'
import '../../App.css'
import axios from 'axios'

import loadingimg from '../../../_metronic/assets/loading.gif'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Card, Col, Image, Row} from 'react-bootstrap'
import LibraryIcon from '../../../_metronic/assets/svgIcon/LibraryIcon'

interface Props {
  type?: string
  selectedLibraryId?: string[]
  setSelectedLibraryIds: any
}

interface Library {
  file_paths: string[]
  name: string
  libraryId: string
}

function LibraryListing({type, selectedLibraryId, setSelectedLibraryIds}: Props) {
  const [searchLibraryName, setSearchLibraryName] = useState('')

  const [libraryData, setLibraryData] = useState<Library[]>()

  const [isLoading, setIsLoading] = useState(true)

  const getAllLibraryData = async () => {
    await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/libraries`).then((response) => {
      const libararyDataKeys = Object.keys(response.data)
      const libraryData = libararyDataKeys
        ? libararyDataKeys.map((item) => ({
            ...response.data[item],
            libraryId: item,
          }))
        : []
      setLibraryData(libraryData)
    })
    setIsLoading(false)
  }

  const [previousFolders, setPreviousFolders] = useState([])
  const [currentPath, setCurrentPath] = useState('uploaded_folders')
  const [previousPaths, setPreviousPaths] = useState([])

  useEffect(() => {
    getAllLibraryData()
  }, [])

  const searchLibrary = libraryData?.filter((item) =>
    item.name.toLowerCase().includes(searchLibraryName.toLocaleLowerCase())
  )

  return (
    <>
      <div className='card card-flush'>
        <div className='card-header p-0'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search DataSource'
                onChange={(e) => setSearchLibraryName(e.target.value)}
              />
            </div>
          </div>
          <div className='card-toolbar'></div>
        </div>
        <div
          className={`dataTables_wrapper dt-bootstrap4 no-footer dataSource-table ${
            type === 'selectable' && 'mx-0'
          }`}
        >
          {isLoading ? (
            <div className='d-flex justify-content-center w-100 align-items-center h-250px'>
              <img
                style={{width: '70px', objectFit: 'contain'}}
                src={loadingimg}
                alt='loading...'
              />
            </div>
          ) : (
            <Row md={3} className='g-5 mb-5 mx-0'>
              {searchLibrary ? (
                searchLibrary.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer'>
                      <label
                        className={`d-flex flex-stack cursor-pointer w-100 ${
                          type !== 'selectable' && 'py-8 flex-column'
                        }`}
                      >
                        <span
                          className='form-check form-check-custom form-check-solid position-absolute'
                          style={{right: 6, top: 6}}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={_?.libraryId}
                            onChange={() => setSelectedLibraryIds(_?.libraryId)}
                            checked={selectedLibraryId?.includes(_?.libraryId)}
                          />
                        </span>
                        <Card.Body className='p-3'>
                          <div className='row no-gutters'>
                            <div className='col-md-auto'>
                              <div className='symbol symbol-75px m-0 w-100'>
                                <div className='symbol-label bg-light-primary p-5'>
                                  <LibraryIcon classes='w-75px' />
                                </div>
                              </div>
                            </div>
                            <div className='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                              <p className='card-title pt-1'>{_?.name}</p>
                              {/* <div className='d-flex gap-2 col-12'>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                data-kt-menu-placement='bottom-end'
                                data-bs-target='#kt_modal_view_app'
                                data-bs-toggle='modal'
                                onClick={() => getlibrarybyid(_.libraryId)}
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                              </button>
                              <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <KTIcon iconName='pencil' className='fs-3' />
                              </button>
                              <button className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'>
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </Card.Body>
                      </label>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className='col-12 col-md-12 text-center'>
                  <Image
                    src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                    className='mw-200px'
                  />
                  <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                  <div className='fs-6 pb-5'>
                    Start creating new folders or uploading a new file!
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>
    </>
  )
}

export default LibraryListing
