import * as React from 'react'

interface Props {
  classes: string
}

const SearchIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <path
        d='m306.161 335.555 12.582 12.587 14.71 14.633 5.668 5.709 29.35-29.371-32.909-32.96z'
        style={{}}
        fill='#5d6d7e'
        data-original='#5d6d7e'
      />
      <path
        d='m398.4 338.918 101.284 101.279c7.619 7.598 12.347 18.145 12.316 29.745 0 11.62-4.698 22.157-12.316 29.786-7.598 7.588-18.12 12.27-29.74 12.27a41.92 41.92 0 0 1-29.745-12.296L338.921 398.413l59.479-59.495z'
        style={{}}
        fill='#ff8e31'
        data-original='#ff8e31'
      />
      <path
        d='m384.422 352.917 8.23-8.246 101.294 101.294c6.395 6.4 9.912 14.912 9.907 23.972 0 9.085-3.528 17.613-9.907 24.023a5.963 5.963 0 0 1-4.127 1.682 5.877 5.877 0 0 1-4.142-1.697c-2.255-2.286-2.255-5.947 0-8.215 4.224-4.209 6.533-9.846 6.533-15.808a22.017 22.017 0 0 0-6.502-15.721L384.422 352.917z'
        style={{}}
        fill='#fece59'
        data-original='#fece59'
      />
      <path
        d='m338.916 398.413 59.484-59.5-4.436-4.447a14.599 14.599 0 0 0-10.409-4.321c-4.096 0-7.767 1.626-10.419 4.301l-38.682 38.702a14.597 14.597 0 0 0-4.316 10.409 14.59 14.59 0 0 0 4.316 10.378l4.462 4.478zM0 190.162C0 85.156 85.143.003 190.17.003c105.037 0 190.175 85.158 190.175 190.159 0 105.037-85.148 190.175-190.175 190.2C85.143 380.337 0 295.196 0 190.162z'
        style={{}}
        fill='#34495e'
        data-original='#34495e'
      />
      <path
        d='M190.17 359.757c93.635 0 169.582-75.919 169.582-169.597 0-93.624-75.945-169.567-169.582-169.567-93.65.002-169.588 75.94-169.588 169.569 0 93.676 75.938 169.595 169.588 169.595z'
        style={{}}
        fill='#5d6d7e'
        data-original='#5d6d7e'
      />
      <path
        d='M190.17 346.184c86.147 0 156.006-69.832 156.006-156.022 0-86.126-69.862-156.001-156.006-156.001S34.156 104.036 34.156 190.162c0 86.185 69.852 156.022 156.014 156.022z'
        style={{}}
        fill='#cee2f2'
        data-original='#cee2f2'
      />
      <path
        d='M194.273 59.774c-2.657-3.942-7.224-6.41-12.25-6.124-21.535 1.308-41.887 7.373-60.485 18.171-6.548 3.83-8.794 12.27-4.979 18.849 3.814 6.497 12.265 8.809 18.793 4.943 14.835-8.558 31.073-13.42 48.287-14.469 7.562-.394 13.379-6.917 12.91-14.474-.087-2.619-.965-4.974-2.276-6.896zM105.94 105.004c-.732-1.098-1.687-2.117-2.734-2.985-5.906-4.831-14.566-3.891-19.341 2.01-14.054 17.254-23.726 37.955-27.996 59.986-5.051 26.166-2.409 52.956 7.624 77.504 2.924 7.076 10.972 10.399 17.992 7.506 7.04-2.852 10.394-10.87 7.511-17.946-8-19.576-10.076-40.955-6.013-61.839 3.323-17.587 11.049-34.099 22.259-47.869 3.898-4.794 4.001-11.521.698-16.367z'
        style={{}}
        fill='#ffffff'
        data-original='#ffffff'
      />
    </g>
  </svg>
)
export default SearchIcon
