import {useEffect, useState} from 'react'
import '../../App.css'
import axios from 'axios'
import loading from '../../../_metronic/assets/loading.gif'
import {KTIcon} from '../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import PromptIcon from '../../../_metronic/assets/svgIcon/PromptIcon'

function PromptLibraryContainer() {
  const [data, setData] = useState([])
  const [viewdata, setViewData] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [promptTitle, setPromptTitle] = useState('')
  const [promptDescription, setPromptDiscription] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [editPromptModal, setEditPromptModal] = useState()
  const [editpromptTitle, seteditpromptTitle] = useState()
  const [editpromptTemplete, seteditpromptTemplete] = useState()
  const [editprompt, setEditPrompt] = useState()
  const [editpromptid, setEditPromptid] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const baseURL = `${process.env.REACT_APP_FILE_MANAGER_LIST}/get_all_prompts`

  const GetPromptsIdData = async (id) => {
    setEditPromptModal(!editPromptModal)
    setEditPromptid(id)
    await axios
      .get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/prompt/${id}`)
      .then((response) => {
        seteditpromptTitle(response?.data.prompt)
        seteditpromptTemplete(response?.data.prompt_template)
        setViewData(response?.data)
        setEditPrompt(response?.data)
        setEditPromptid(id)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const deletePromptData = async (id) => {
    var answer = window.confirm('Are you sure you want to delete this?')

    if (answer) {
      await axios
        .delete(`${process.env.REACT_APP_FILE_MANAGER_LIST}/delete_prompt/${id}`)
        .then((response) => {
          if (response.status === 200) {
            getPromptData()
          }
        })
    }
  }

  const EditPromptData = async () => {
    const result = await axios.put(
      `${process.env.REACT_APP_FILE_MANAGER_LIST}/edit_prompt/${editpromptid}`,
      {
        prompt: editpromptTitle,
        prompt_template: editpromptTemplete,
      }
    )
    if (result?.status === 200) {
      setModalShow(false)
      getPromptData()
    }
  }

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setData(response.data)
      setIsLoading(false)
    })
  }, [baseURL])

  const filterData = data.filter((item) =>
    item.prompt.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  )

  const handleSubmitPrompt = async () => {
    const result = await axios.post(`${process.env.REACT_APP_FILE_MANAGER_LIST}/save_prompt`, {
      prompt: promptTitle,
      prompt_template: promptDescription,
    })
    if (result?.status === 200) {
      setModalShow(false)
      setPromptTitle('')
      setPromptDiscription('')
      getPromptData()
    }
  }

  const getPromptData = () => {
    axios.get(baseURL).then((response) => {
      setData(response.data)
    })
  }

  useEffect(() => {
    getPromptData()
  }, [])

  return (
    <div>
      <div className='card card-flush'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Prompt Library</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              #{data.length} Prompt{data.length > 1 && 's'}
            </span>
          </h3>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-15 py-2 me-3'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              className='btn btn-sm btn-light-primary'
              id='kt_file_manager_new_folder'
              data-bs-target='#kt_modal_create_app'
              data-bs-toggle='modal'
              onClick={() => setModalShow(!modalShow)}
            >
              <KTIcon iconName='plus' className='fs-3' />
              New Prompt
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {isLoading ? (
              <div className='d-flex justify-content-center'>
                <img
                  style={{height: '100px', width: '100px', objectFit: 'cover'}}
                  src={loading}
                  alt='loading...'
                />
              </div>
            ) : (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>About Prompt</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {filterData?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-4'>
                              <span className='symbol-label bg-light-primary fs-3 text-primary'>
                                <PromptIcon classes='w-25px' />
                              </span>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Button
                                variant='link'
                                className='text-dark fw-bold text-hover-primary fs-6 p-0 text-start'
                              >
                                {item.prompt}
                              </Button>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.prompt_template}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <button
                              className='btn btn-icon btn-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                GetPromptsIdData(item.prompt_id)
                              }}
                              data-kt-menu-placement='bottom-end'
                              id='kt_file_manager_new_folder'
                              data-kt-menu-trigger='click'
                              data-bs-target='#viewPropmtModal'
                              data-bs-toggle='modal'
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </button>
                            <button
                              className='btn btn-icon btn-light btn-active-color-primary btn-sm me-1'
                              onClick={() => GetPromptsIdData(item.prompt_id)}
                              data-kt-menu-trigger='click'
                              data-bs-target='#EditPropmtModal'
                              data-bs-toggle='modal'
                              data-kt-menu-placement='bottom-end'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                            <button
                              className='btn btn-icon btn-light btn-active-color-danger btn-sm'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                              onClick={() => deletePromptData(item.prompt_id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      <div className='modal fade' id='kt_modal_create_app' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-600px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>Create Prompt</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='fv-row mb-6 create-promt-modal'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Prompt Title</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Specify your unique app name'
                        ></span>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control'
                        value={promptTitle}
                        onChange={(e) => setPromptTitle(e.target.value)}
                      />
                    </div>

                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Prompt Template</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Select your app category'
                        ></span>
                      </label>

                      <textarea
                        className='form-control'
                        id='exampleFormControlTextarea1'
                        rows='4'
                        value={promptDescription}
                        onChange={(e) => setPromptDiscription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer pt-0 border-0 px-8'>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='next'
                onClick={handleSubmitPrompt}
                data-bs-dismiss='modal'
              >
                Create Prompt
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* view modal */}
      <div className='modal fade' id='viewPropmtModal' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-600px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>View Prompt</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
            <div className='modal-body py-12 px-10'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='fv-row mb-6 create-promt-modal'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span>Prompt Title</span>
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control'
                        value={viewdata?.prompt}
                      />
                    </div>

                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span>Prompt Template</span>
                      </label>

                      <textarea
                        className='form-control'
                        id='exampleFormControlTextarea1'
                        rows='4'
                        value={viewdata?.prompt_template}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <div className='modal fade' id='EditPropmtModal' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-600px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>Edit Prompt</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='fv-row mb-6 create-promt-modal'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Prompt Title</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Specify your unique app name'
                        ></span>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control'
                        value={editpromptTitle}
                        onChange={(event) => seteditpromptTitle(event.target.value)}
                      />
                    </div>

                    <div className='fv-row'>
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Prompt Template</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Select your app category'
                        ></span>
                      </label>

                      <textarea
                        className='form-control'
                        id='exampleFormControlTextarea1'
                        rows='4'
                        value={editpromptTemplete}
                        onChange={(event) => seteditpromptTemplete(event.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer pt-0 border-0 px-8'>
              <button
                type='button'
                className='btn btn-primary'
                data-kt-stepper-action='next'
                onClick={() =>
                  EditPromptData(
                    editprompt?.prompt_id,
                    editprompt?.prompt,
                    editprompt?.prompt_template
                  )
                }
                data-bs-dismiss='modal'
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromptLibraryContainer
