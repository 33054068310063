import * as React from 'react'

interface Props {
  classes: string
}

const CrawlWebpageIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <path
        fill='#6aabf0'
        d='M477.866 358.4c18.851 0 34.132-15.282 34.132-34.132V102.399L256 68.155.002 102.399v221.868c0 18.851 15.281 34.132 34.132 34.132h443.732z'
        data-original='#6aabf0'
        className=''
      />
      <path
        fill='#4596ec'
        d='M488.304 99.229v225.038c0 18.851-15.282 34.132-34.132 34.132h23.694c18.851 0 34.132-15.282 34.132-34.132V102.399z'
        data-original='#4596ec'
        className=''
      />
      <path
        fill='#35495e'
        d='M511.998 102.4V34.132C511.998 15.282 496.717 0 477.866 0H34.134C15.283 0 .002 15.282.002 34.132V102.4z'
        data-original='#35495e'
        className=''
      />
      <path
        fill='#6ed5a1'
        d='M42.668 58.927h-8.533a7.726 7.726 0 1 1 0-15.452h8.533a7.726 7.726 0 1 1 0 15.452z'
        data-original='#6ed5a1'
      />
      <path
        fill='#ee6161'
        d='M93.867 58.927h-8.533a7.726 7.726 0 1 1 0-15.452h8.533a7.726 7.726 0 1 1 0 15.452z'
        data-original='#ee6161'
      />
      <path
        fill='#f9ce69'
        d='M145.067 58.927h-8.533a7.726 7.726 0 1 1 0-15.452h8.533c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z'
        data-original='#f9ce69'
      />
      <path
        fill='#f5f5f5'
        d='M469.867 68.267H195.734a8 8 0 0 1-8-8V42.133a8 8 0 0 1 8-8h274.133a8 8 0 0 1 8 8v18.133a8 8 0 0 1-8 8.001zM475.367 229.365l-28.849-28.849a4.267 4.267 0 0 1 0-6.034l8.644-8.643c2.558-2.558.972-6.939-2.631-7.266l-52.203-4.746a4.266 4.266 0 0 0-4.635 4.635l4.746 52.203c.328 3.603 4.708 5.189 7.266 2.631l8.643-8.644a4.267 4.267 0 0 1 6.034 0l28.849 28.849a8.534 8.534 0 0 0 12.068 0l12.068-12.068a8.532 8.532 0 0 0 0-12.068z'
        data-original='#f5f5f5'
        className=''
      />
      <circle
        cx={256}
        cy={358.4}
        r={153.6}
        fill='#f5f5f5'
        transform='rotate(-45 255.955 358.474)'
        data-original='#f5f5f5'
        className=''
      />
      <path
        fill='#e0e0e0'
        d='M256 204.8c-3.986 0-7.937.152-11.847.451 79.295 6.049 141.752 72.305 141.752 153.149s-62.458 147.101-141.752 153.149c3.91.298 7.861.45 11.847.45 84.831 0 153.6-68.769 153.6-153.6 0-84.83-68.769-153.599-153.6-153.599z'
        data-original='#e0e0e0'
      />
      <circle
        cx={256}
        cy={358.4}
        r={119.467}
        fill='#6aabf0'
        transform='rotate(-45 255.955 358.474)'
        data-original='#6aabf0'
        className=''
      />
      <path
        fill='#4596ec'
        d='M256 238.934c-3.998 0-7.95.2-11.847.584 60.417 5.948 107.619 56.901 107.619 118.882S304.57 471.334 244.153 477.282c3.897.384 7.849.584 11.847.584 65.979 0 119.466-53.487 119.466-119.467.001-65.978-53.486-119.465-119.466-119.465z'
        data-original='#4596ec'
        className=''
      />
      <path
        fill='#4596ec'
        d='M220.757 445.098c-10.81-23.029-16.763-53.819-16.763-86.698s5.953-63.669 16.763-86.699c9.351-19.92 21.484-31.72 33.534-32.746-12.323.173-24.193 2.211-35.344 5.846-4.417 5.713-8.504 12.508-12.178 20.335-11.754 25.041-18.228 58.162-18.228 93.264s6.473 68.224 18.228 93.264c3.674 7.826 7.761 14.622 12.177 20.335 11.15 3.635 23.02 5.672 35.342 5.846-12.049-1.028-24.181-12.828-33.531-32.747zM291.244 271.702c10.81 23.029 16.764 53.819 16.764 86.699 0 32.879-5.954 63.669-16.764 86.698-9.35 19.919-21.482 31.719-33.531 32.747 12.322-.173 24.192-2.211 35.342-5.846 4.417-5.713 8.504-12.508 12.178-20.335 11.754-25.041 18.227-58.163 18.227-93.264 0-35.102-6.474-68.224-18.227-93.264-3.674-7.827-7.761-14.622-12.178-20.335-11.151-3.635-23.021-5.672-35.344-5.846 12.049 1.026 24.182 12.826 33.533 32.746z'
        data-original='#4596ec'
        className=''
      />
      <g fill='#4596ec'>
        <path
          d='M163.196 285.501c23.769 13.277 57.595 20.892 92.805 20.892 35.209 0 69.035-7.615 92.805-20.892a7.77 7.77 0 0 0 1.201-.833 120.241 120.241 0 0 0-10.522-11.713c-21.452 11.437-51.709 17.985-83.483 17.985s-62.031-6.548-83.483-17.985a120.264 120.264 0 0 0-10.523 11.713c.367.309.765.59 1.2.833zM136.534 358.4c0 2.596.092 5.171.256 7.727h238.422c.163-2.555.255-5.13.255-7.727 0-2.596-.092-5.171-.255-7.726H136.789a121.29 121.29 0 0 0-.255 7.726zM348.805 431.299c-23.77-13.277-57.596-20.892-92.805-20.892s-69.035 7.615-92.804 20.892a7.722 7.722 0 0 0-1.201.833 120.241 120.241 0 0 0 10.522 11.713c21.452-11.436 51.709-17.985 83.483-17.985 31.773 0 62.031 6.548 83.483 17.985a120.008 120.008 0 0 0 10.522-11.713 7.666 7.666 0 0 0-1.2-.833z'
          fill='#4596ec'
          data-original='#4596ec'
          className=''
        />
      </g>
      <path
        fill='#e0e0e0'
        d='m475.367 229.365-28.849-28.848a4.267 4.267 0 0 1 0-6.034l8.644-8.643c2.558-2.558.972-6.939-2.631-7.266l-25.495-2.318c3.156.742 4.417 4.758 2.011 7.164l-8.644 8.643a4.267 4.267 0 0 0 0 6.034l31.269 31.268a8.534 8.534 0 0 1 0 12.068l-6.255 6.255 5.813 5.813a8.534 8.534 0 0 0 12.068 0l12.068-12.068a8.532 8.532 0 0 0 .001-12.068z'
        data-original='#e0e0e0'
      />
      <path
        fill='#222f3d'
        d='M477.866 0h-23.692c18.851 0 34.132 15.282 34.132 34.132V102.4h23.692V34.132C511.998 15.282 496.717 0 477.866 0z'
        data-original='#222f3d'
        className=''
      />
      <path
        fill='#e0e0e0'
        d='M469.625 34.133H445.93a8.24 8.24 0 0 1 8.241 8.241v17.65a8.24 8.24 0 0 1-8.241 8.241h23.695a8.24 8.24 0 0 0 8.241-8.241v-17.65a8.24 8.24 0 0 0-8.241-8.241z'
        data-original='#e0e0e0'
      />
    </g>
  </svg>
)
export default CrawlWebpageIcon
