/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

import {auth, provider, FBprovider} from '../../../../firebase'

import {signInWithPopup} from 'firebase/auth'
import {useDispatch} from 'react-redux'
import {onLogIn} from '../../../../store/Auth/AuthActions'
import {useNavigate} from 'react-router-dom'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const naviagte = useNavigate()
  const dispatch = useDispatch()

  interface User {
    email: string;
    // add any other fields you have in your user object
  }

  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        const user: User = {email: data.user.email}
        localStorage.setItem('email', user.email)
        naviagte('/')
        window.location.reload()
        dispatch(onLogIn(data))
      })
      .catch((error) => {
        console.error('Error signing in with Google:', error)
        // handle error
      })
  }

  const handleFacebookLogin = () => {
    signInWithPopup(auth, FBprovider).then((data) => {
      localStorage.setItem('email', data.user.email)
      naviagte('/')
      window.location.reload()
      dispatch(onLogIn(data))
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}


      <div className='row g-3 mb-9'>

        {/* <div className='col-md-6'>

          <a
            onClick={handleGoogleLogin}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>

        </div>



        <div className='col-md-6'>

          <a
            onClick={handleFacebookLogin}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/fb.png')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Facebook
          </a>

        </div> */}

        <div className='d-flex justify-content-center'>
          <a className='loginBtn loginBtn--facebook d-flex' onClick={handleFacebookLogin}>
            Sign in with Facebook
          </a>
        </div>
        <div className='d-flex justify-content-center'>
          <a className='loginBtn loginBtn--google d-flex'>
            <span className='mx-3' onClick={handleGoogleLogin}>
              Sign in with Google
            </span>
          </a>
        </div>
        
      </div>



    </form>
  )
}
