import {useEffect, useState} from 'react'
import {Card, Col, Image, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import '../../App.css'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import loadingimg from '../../../_metronic/assets/loading.gif'
import LoadingButton from '../../../_metronic/layout/components/LoadingButton'
import LoadingButtonIcon from '../../../_metronic/layout/components/LoadingButtonIcon'
import {toast} from 'react-toastify'

function FolderUploadContainer() {
  const [searchTerm, setSearchTerm] = useState('')
  const [show, setShow] = useState(false)
  const [data, setData] = useState([])
  const [addFolder, setAddfolder] = useState(false)
  const [folderData, setFolderData] = useState(undefined)
  const [allfolders, setAllFolders] = useState(undefined)
  let [currentPath, setCurrentPath] = useState('uploaded_folders')
  const [loading, setLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [processing, setProcessing] = useState({createNewFolder: false})
  const [datasourceName, setDatasourceName] = useState(undefined)
  const [name, setName] = useState(undefined)
  const baseURL = `${process.env.REACT_APP_FILE_MANAGER_LIST}/file_manager`

  const handleToggle = () => {
    setSelectedFiles()
    setShow(!show)
  }

  const formatFileSize = function (bytes) {
    const sufixes = ['B', 'kB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`
  }

  const handleChange = (e) => {
    setSelectedFiles(e.target.files)
  }

  let totalSize = 0
  selectedFiles &&
    Object.values(selectedFiles) &&
    Object.values(selectedFiles).map((row) => (totalSize += row.size))
  const folderSize = (totalSize / 1000000).toFixed(2)

  const getData = (fileName = undefined) => {
    axios.get(baseURL).then((response) => {
      setData(response.data.files_list)
      const tempData = organizeFiles(response.data.files_list).filter((row) => row?.name !== '')
      handleFolder(fileName, tempData)
      setIsLoading(false)
      setProcessing({...processing, createNewFolder: false})
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const organizeFiles = (fileList) => {
    const root = {name: 'root', children: []}

    fileList.forEach((filePath) => {
      const pathParts = filePath.split('/')

      let currentNode = root
      console.log('Test')
      pathParts.forEach((part, index) => {
        if (!currentNode.children) {
          currentNode.children = []
        }

        const isLastPart = index === pathParts.length - 1
        const isFolder = !isLastPart
        if (part !== '') {
          let node = currentNode.children.find((n) => n.name === part)
          if (!node) {
            node = {name: part, filePath}
            currentNode.children.push(node)
          }

          if (isFolder) {
            currentNode = node
          } else {
            node.filePath = filePath
          }
        }
      })
    })

    if (root.children.length === 1 && root.children[0].name === 'uploaded_folders') {
      return root.children[0].children
    }

    return root.children
  }

  const foundData = []
  const findDeep = (dat, activity) => {
    dat.map((item) =>
      [item].some((e) => {
        if (e.filePath.includes(activity)) {
          foundData.push(e)
          return true
        } else if (e.children) return findDeep(e.children, activity)
      })
    )
    return foundData
  }

  const handleFolder = (name, dataArray = allfolders) => {
    setSearchTerm('')
    const activePath = name ? `${currentPath}/${name}` : `${currentPath}`
    const selectedFolder = findDeep(dataArray, activePath)
    const tempData1 = selectedFolder?.map((row) => row.children)
    switch (name) {
      case '':
        if (activePath.includes('/'))
          selectedFolder[0].children.length
            ? setAllFolders(selectedFolder[0].children)
            : setAllFolders(selectedFolder)
        else {
          setAllFolders(selectedFolder)
        }
        break
      case undefined:
        setAllFolders(selectedFolder)
        break
      default:
        setAllFolders(tempData1[0])
        break
    }
    setCurrentPath(`${activePath}`)
  }

  const goBack = () => {
    if (!currentPath.includes('/')) return
    const parts = currentPath.split('/')
    parts.pop()
    if (parts.length > 1) {
      const tempData = organizeFiles(data).filter((row) => row?.name !== '')
      const selectedFolder = findDeep(tempData, `${parts.join('/')}/`)
      const tempData1 = selectedFolder?.map((row) => row.children)
      const child = tempData1[0]?.filter((row) => row?.name !== '')
      setAllFolders(child)
      setCurrentPath(parts.join('/'))
    } else {
      setAllFolders(organizeFiles(data).filter((row) => row?.name !== ''))
      setCurrentPath('uploaded_folders')
    }
  }

  const filteredCategories =
    allfolders !== undefined
      ? allfolders.filter(
          (folder) =>
            folder?.name && folder?.name.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
      : []

  const handleModal = () => {
    setSelectedFiles()
    setModalShow(!modalShow)
  }

  const AddFolderData = async () => {
    const name = allfolders.filter((name) => name.name === folderData)
    if (name.length === 0) {
      setProcessing({...processing, createNewFolder: true})
      const result = await axios.post(`${process.env.REACT_APP_FILE_UPLOAD}/create_folder`, {
        folder_name: folderData,
        save_path: currentPath,
      })
      if (result?.status === 200) {
        getData('')
        setAddfolder(false)
      }
    } else {
      alert(`Folder with name "${folderData}" already exist. Please try another one.`)
    }
  }

  const UploadFiles = async () => {
    setLoading(true)
    let formData = new FormData()

    selectedFiles !== undefined &&
      Object.values(selectedFiles).length > 0 &&
      Object.values(selectedFiles).map((row) => formData.append('files[]', row))

    formData.append('save_path', currentPath)
    await axios.post(`${process.env.REACT_APP_FILE_UPLOAD}/upload`, formData).then(() => {
      getData('')
    })
    setSelectedFiles()
    setLoading(false)
    setShow(false)
  }

  const uploadFolder = async () => {
    const tempData = new FormData()
    setLoading(true)
    selectedFiles !== undefined &&
      Object.values(selectedFiles).length > 0 &&
      Object.values(selectedFiles).map((row) => tempData.append('files[]', row))
    tempData.append('save_path', currentPath)
    await axios.post(`${process.env.REACT_APP_FILE_UPLOAD}/upload`, tempData).then(() => {
      getData('')
    })
    setLoading(false)
    setSelectedFiles()
    setModalShow(false)
  }

  const getCrums = (currentPath) => {
    const t = currentPath.split('/')
    return t.map((item, index) =>
      item === 'uploaded_folders' ? (
        <i className='ki-duotone ki-bank fs-2 text-primary me-1'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      ) : (
        <>
          <p className='mx-1 mb-0'>{item}</p>
          {t.length - 2 >= index && <i className='ki-duotone ki-right fs-2 text-primary'></i>}{' '}
        </>
      )
    )
  }

  const createDataSource = async () => {
    const tempData = `${currentPath}/${name}`
    await axios
      .get(
        `${process.env.REACT_APP_DATASOURCE_LIST}/process_directory?filepath=${tempData}&datasource_name=${datasourceName}`
      )
      .then((response) => {
        toast.success(response.data.message)
      })
      .catch((error) => {
        toast.error(error.data.message)
      })
  }

  return (
    <>
      <div>
        <div className='card card-flush'>
          <div className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat card_style'>
            <div className='card-header p-10 pb-0 bg-white'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-circle me-5'>
                  <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed'>
                    <i className='ki-duotone ki-abstract-47 fs-2x text-primary'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <h2 className='mb-1'>File Manager</h2>
                  <div className='text-muted fw-bold'></div>
                </div>
              </div>
              <div className='d-flex align-items-center position-relative my-1'>
                <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <input
                  type='text'
                  className='form-control form-control-solid w-250px ps-15'
                  placeholder='Search Files &amp; Folders'
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-header pt-8'>
            <div className='card-title'></div>

            <div className='card-toolbar'>
              <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
                <button
                  onClick={() => setAddfolder(true)}
                  type='button'
                  className='btn btn-flex btn-light-primary btn-sm'
                  id='kt_file_manager_new_folder'
                >
                  <i className='ki-duotone ki-add-folder fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  New Folder
                </button>

                <button
                  type='button'
                  onClick={handleToggle}
                  className='btn btn-flex btn-primary mx-2 btn-sm'
                >
                  <i className='ki-duotone ki-folder-up fs-2'></i>
                  Upload Files
                </button>
                <button
                  type='button'
                  onClick={handleModal}
                  className='btn btn-flex btn-primary btn-sm'
                >
                  <i className='ki-duotone ki-folder-up fs-2'></i>
                  Upload Folders
                </button>
              </div>

              <div
                className='d-flex justify-content-end align-items-center d-none'
                data-kt-filemanager-table-toolbar='selected'
              >
                <div className='fw-bold me-5'>
                  <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                  Selected
                </div>
                <button
                  type='button'
                  className='btn btn-danger'
                  data-kt-filemanager-table-select='delete_selected'
                >
                  Delete Selected
                </button>
              </div>
            </div>
          </div>
          <div className='card-body d-flex flex-stack'>
            <div className='badge badge-lg badge-light-primary'>
              <div className='d-flex align-items-center flex-wrap'>{getCrums(currentPath)}</div>
            </div>
            <div className='badge badge-lg badge-primary'>
              <span id='kt_file_manager_items_counter'>{filteredCategories.length} items</span>
            </div>
          </div>

          <div
            className='dataTables_wrapper dt-bootstrap4 no-footer upload-folder-table'
            id='kt_file_manager_list_wrapper'
          >
            {currentPath.includes('/') && (
              <button className='btn btn-link mb-2' onClick={goBack}>
                <i className='ki-duotone ki-left fs-2'></i>Back
              </button>
            )}
            {isLoading ? (
              <div className='d-flex justify-content-center'>
                <img
                  style={{height: '100px', width: '100px', objectFit: 'cover'}}
                  src={loadingimg}
                  alt='loading...'
                />
              </div>
            ) : (
              <Row xs md={5} className='g-10 mb-8'>
                {addFolder && (
                  <Col>
                    <Card className='text-center border border-secondary'>
                      <i className='ki-duotone ki-folder fs-5x text-primary m-auto py-5'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <Card.Body className='p-3 pt-0'>
                        <input
                          type='text'
                          name='new_folder_name'
                          placeholder='Enter the folder name'
                          className='form-control mw-250px me-3 p-2'
                          onChange={(e) => setFolderData(e.target.value)}
                        />
                        <div className='d-flex gap-2 col-12 mt-2'>
                          <LoadingButtonIcon
                            isLoading={processing.createNewFolder}
                            submitButtonAction={AddFolderData}
                            cancelButtonAction={setAddfolder}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {Object.keys(filteredCategories).length ? (
                  filteredCategories.map((_, index) => (
                    <Col>
                      <Card className='text-center border border-secondary  cursor-pointer'>
                        <OverlayTrigger
                          placement='bottom'
                          overlay={<Tooltip data-bs-theme='dark'>Create Datasource</Tooltip>}
                        >
                          <span
                            className='symbol-label bg-light-success position-absolute btn-icon btn-sm btn'
                            style={{right: 6, top: 6}}
                            id='kt_file_manager_new_folder'
                            data-bs-target='#kt_modal_view_app'
                            data-bs-toggle='modal'
                            onClick={() => setName(_?.name)}
                          >
                            <i className='ki-duotone ki-abstract-26 fs-3 text-success'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          </span>
                        </OverlayTrigger>
                        <Card.Img
                          variant='top'
                          className='m-auto pt-10'
                          style={{width: 80}}
                          src={
                            _?.name.match(/\.[^\/]+$/)
                              ? toAbsoluteUrl('/media/file.png')
                              : toAbsoluteUrl('/media/folder/folder.png')
                          }
                          onClick={_?.name.match(/\.[^\/]+$/) ? null : () => handleFolder(_?.name)}
                        />
                        <Card.Body className='p-3 pt-0'>
                          <p className='mb-5 pb-2 card-title'>{_?.name}</p>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col className='col-12 col-md-12 text-center'>
                    <Image
                      src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                      className='mw-300px'
                    />
                    <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                    <div className='fs-6 pb-5'>
                      Start creating new folders or uploading a new file!
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </div>

        {show ? (
          <Modal
            size='md'
            aria-labelledby='contained-modal-title-center'
            centered
            show={show}
            onHide={() => handleToggle()}
          >
            <Modal.Header closeButton>
              <Modal.Title className='file-upload-text m-auto'>Upload Files</Modal.Title>
            </Modal.Header>
            <Modal.Body className=' justify-content-between'>
              <form className='file-btn m-0' id='my-form' encType='multipart/form-data'>
                <input
                  type='file'
                  name='file-input'
                  id='mutipleFileUpload'
                  className='file-input__input'
                  onChange={(event) => setSelectedFiles(event.target.files)}
                  multiple
                />
                <label
                  className='file-input__label border-primary w-100 bg-white text-primary p-15 d-block text-center bg-light-primary'
                  for='mutipleFileUpload'
                  style={{borderStyle: 'dashed'}}
                >
                  <div className='w-100'>Attach file</div>
                  {selectedFiles &&
                    Object.keys(selectedFiles).map((row) => {
                      const _ = selectedFiles[row]
                      return (
                        <div className='w-100'>
                          {_?.name} (<strong>{formatFileSize(_?.size)}</strong>)
                        </div>
                      )
                    })}
                </label>
                {selectedFiles ? (
                  <div className='dropzone-items wm-200px '>
                    <div className='dropzone-file'>
                      <div
                        className='dropzone-filename text-dark d-flex'
                        style={{justifyContent: 'space-between'}}
                      >
                        <span data-dz-name=''></span>
                      </div>
                      {(selectedFiles.size / 1000000).toFixed(2) > 17 && (
                        <div
                          className='dropzone-error'
                          data-dz-errormessage=''
                          style={{color: 'red'}}
                        >
                          File is too big ({(selectedFiles.size / 1000000).toFixed(2)}{' '}
                          {(selectedFiles.size / 1000000).toFixed(2) >= 1024 ? 'GB' : 'MB'}.) Max
                          filesize: 17MB.
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </form>
              {selectedFiles?.length && folderSize <= 17 && (
                <LoadingButton
                  isLoading={loading}
                  buttonClasses='btn-sm btn-success w-100 mt-10'
                  submitButtonAction={() => UploadFiles()}
                >
                  {loading ? 'Uploading...' : 'Upload File'}
                </LoadingButton>
              )}
            </Modal.Body>
          </Modal>
        ) : null}

        {modalShow ? (
          <Modal
            size='lg'
            aria-labelledby='contained-modal-title-center'
            centered
            show={modalShow}
            onHide={() => handleModal()}
            scrollable={true}
          >
            <Modal.Header closeButton>
              <Modal.Title className='file-upload-text'>Upload Folder</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
              {selectedFiles && (
                <div className='table-responsive p-5 tableFixHead'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>
                          File{selectedFiles.length && 's'} (#{selectedFiles.length})
                        </th>
                        <th className='min-w-140px'>Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedFiles &&
                        Object.keys(selectedFiles).map((row) => {
                          const item = selectedFiles[row]
                          return (
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a
                                      href='#'
                                      className='text-dark fw-bold text-hover-primary fs-6'
                                    >
                                      {item.name}
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {item.type}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary fs-6'>
                                  {formatFileSize(item.size)}
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
              <form className='file-btn m-0' id='my-form' enctype='multipart/form-data'>
                <input
                  type='file'
                  name='file-input'
                  id='file'
                  className='file-input__input'
                  directory=''
                  webkitdirectory=''
                  onChange={(e) => handleChange(e)}
                />
                <label className='file-input__label m-0' for='file'>
                  <span>Attach Folder</span>
                </label>
                {folderSize > 17 ? (
                  <div className='dropzone-error' data-dz-errormessage='' style={{color: 'red'}}>
                    File is too big ({folderSize} {folderSize >= 1024 ? 'GB' : 'MB'}.) Max filesize:
                    17MB.
                  </div>
                ) : null}
              </form>
              {selectedFiles && folderSize <= 17 && (
                <LoadingButton
                  isLoading={loading}
                  buttonClasses='btn-sm btn-success m-0'
                  submitButtonAction={() => uploadFolder()}
                >
                  {loading ? 'Uploading...' : 'Upload Folder'}
                </LoadingButton>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}

        {/* create datasource modal */}

        <div className='modal fade' id='kt_modal_view_app' tabIndex='-1' aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered mw-500px'>
            <div className='modal-content'>
              <div className='modal-header py-3'>
                <h2 className='mb-0'></h2>
                <h3 className='mb-0'>Create DataSources</h3>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                >
                  <i className='ki-duotone ki-cross fs-1' onClick={() => setDatasourceName('')}>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='name'
                  value={datasourceName}
                  onChange={(e) => setDatasourceName(e.target.value)}
                  required={true}
                  placeholder='Datasource Name'
                />
                <button
                  data-bs-dismiss='modal'
                  type='button'
                  className='btn btn-lg btn-primary w-100 btn-sm mt-15'
                  data-kt-stepper-action='next'
                  onClick={() => {
                    createDataSource(datasourceName)
                    setDatasourceName('')
                  }}
                >
                  Create Datasource
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FolderUploadContainer
