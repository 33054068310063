import {useEffect, useState} from 'react'
import '../../App.css'
import axios from 'axios'
import {Button, Modal, Form as BootForm} from 'react-bootstrap'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'

interface Props {
  viewActionLibrary: {isShowModal: boolean; actionId: string}
  setViewActionLibrary: any
}

interface ViewActionFormik {
  action_type: string
  html_form: string
}

function ViewActionLibrary({viewActionLibrary, setViewActionLibrary}: Props) {
  const [actionFormData, setActionFormData] = useState<ViewActionFormik>()

  const getActionLibraryData = async (id: string) => {
    await axios
      .get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/get_form_id?id=${id}`)
      .then((response) => {
        console.log(response)
        setActionFormData(response.data.form)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    viewActionLibrary.actionId && getActionLibraryData(viewActionLibrary.actionId)
  }, [viewActionLibrary.actionId])

  return (
    <Modal show={viewActionLibrary.isShowModal} centered size='lg' scrollable>
      <Modal.Header className='p-4 border-0'>
        <h3 className='mb-0 m-auto'>View Action Library</h3>
        <button
          type='button'
          className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
          onClick={() => setViewActionLibrary({...viewActionLibrary, isShowModal: false})}
        >
          <KTIcon iconName='cross' className='fs-2' />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className='current'>
          <div className='fv-row h-300px mh-300px overflow-scroll'>
            <div dangerouslySetInnerHTML={{__html: actionFormData?.html_form || ''}}></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        {/* <Button
          variant='light'
          className='btn-sm'
          onClick={() => setViewActionLibrary({...viewActionLibrary, isShowModal: false})}
        >
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default ViewActionLibrary
