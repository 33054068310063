import axios from 'axios'

export const getPromptDataById = async (promptId: string) => {
  const res = await axios
    .get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/prompt/${promptId}`)
    .then((response) => response)
    .catch((error) => error)
  return res
}

export const createNewPrompt = async (promptTitle: string, promptDescription: string) => {
  const res = await axios
    .post(`${process.env.REACT_APP_FILE_MANAGER_LIST}/save_prompt`, {
      prompt: promptTitle,
      prompt_template: promptDescription,
    })
    .then((response) => response)
    .catch((error) => error)
  return res
}
