/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import CreateFlow from '../../../_metronic/partials/modals/create-new-flow'
import axios from 'axios'
import {
  Button,
  Card,
  Col,
  Form as BootForm,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import DataSourceListing from '../datasource/DataSourceListing'
import LibraryListing from '../library/LibraryListing'
import PromptListing from '../PromptLibrary/PromptListing'
import ActionLibraryListing from '../ActionLibrary/ActionLibraryListing'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LibraryIcon from '../../../_metronic/assets/svgIcon/LibraryIcon'
import ActionIcon from '../../../_metronic/assets/svgIcon/ActionIcon'
import PromptIcon from '../../../_metronic/assets/svgIcon/PromptIcon'
import EditPrompt from '../PromptLibrary/EditPrompt'
import loadingImg from '../../../_metronic/assets/loading.gif'
import ViewActionLibrary from '../ActionLibrary/ViewActionLibrary'
import LoadingButton from '../../../_metronic/layout/components/LoadingButton'
import LoadingIconButton from '../../../_metronic/layout/components/LoadingIconButton'
import {createNewPrompt, getPromptDataById} from '../../services/PromptServices'
import {
  ActionSource,
  CreateFlowProps,
  CreateStep,
  EditPromptProps,
  EditStep,
  FlowSouce,
  ReplyWindow,
  StepActionSource,
  StepsSource,
  ViewActionLibraryProps,
} from './FlowDataTypes'
import {useParams, useSearchParams} from 'react-router-dom'

const createStepsValidationSchema = [
  Yup.object({
    stepName: Yup.string().required().label('Step Title'),
  }),
  Yup.object({
    libraryIds: Yup.array().required().label('Library'),
  }),
  Yup.object({
    libraryIds: Yup.array().required().label('Datasource'),
  }),
  Yup.object({
    promptContent: Yup.string().required().label('Prompt'),
  }),
  Yup.object({
    actions: Yup.array().required().label('Action'),
  }),
]

function FlowWebhook() {
  const [flowData, setFlowData] = useState<FlowSouce[] | undefined>(undefined)
  const [createFlowActions, setCreateFlowActions] = useState<CreateFlowProps>({isShowModal: false})
  const [action, setAction] = useState<ActionSource>()
  const [editStepAction, setEditStepAction] = useState<EditStep>({isEditEnabled: false, stepId: ''})
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [visibility, setVisiblity] = useState<string>('')
  const [statuss, setStatuss] = useState<string>('')
  const [showCopied, setShowCopied] = useState(false)
  const [editPrompt, setEditPrompt] = useState<EditPromptProps>({
    isShowModal: false,
    promptId: '',
  })
  const [viewActionLibrary, setViewActionLibrary] = useState<ViewActionLibraryProps>({
    isShowModal: false,
    actionId: '',
  })
  const [showReplyWindow, setShowReplyWindow] = useState<ReplyWindow[]>([])
  const [stepActions, setStepActions] = useState<StepActionSource>({
    type: '',
    validationSchema: 0,
    showModal: false,
    isLoadingSteps: false,
    isProcessingAttachStep: false,
    isProcessingDetachStep: false,
    detachingId: '',
    isProcessingPlayStep: false,
  })
  const {flowId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const fetchFlowById = async (flowId: string, actionType: string = '') => {
    setStepActions({...stepActions, isProcessingPlayStep: true})
    await axios
      .get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_flow/${flowId}`)
      .then((response) => {
        fetchStepsInfo(response.data, flowId, false)
      })
  }

  const updateFlowVisibility = async (getVisibility: string) => {
    const modifiedFlowData = {...action?.flowData, visibility: getVisibility || visibility}
    const flowDataJson = JSON.stringify(modifiedFlowData)
    await axios
      .put('https://flow-step-w4o7wofqcq-wl.a.run.app/update_flow', {
        flowData: flowDataJson,
        flow_id: action?.flowId,
      })
      .then((response) => {
        fetchFlowById(action?.flowId ?? '', 'new')
        toast.success(response.data.message)
      })
  }

  const updateFlowStatus = async (getStatus: string) => {
    const modifiedFlowData = {...action?.flowData, status: getStatus || statuss}

    const flowDataJson = JSON.stringify(modifiedFlowData)
    await axios
      .put('https://flow-step-w4o7wofqcq-wl.a.run.app/update_flow', {
        flowData: flowDataJson,
        flow_id: action?.flowId,
      })
      .then((response) => {
        fetchFlowById(action?.flowId ?? '', 'new')
        toast.success(response.data.message)
      })
  }
  const handleCopy = (text: string) => {
    if (text !== '') {
      navigator.clipboard.writeText(text)
      setShowCopied(true)
      setTimeout(() => setShowCopied(false), 1500)
    }
  }

  // const fetchFlow = async () => {
  //   await axios.get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_all_flows`).then((response) => {
  //     const flowKeys = Object.keys(response.data)

  //     const flowData = flowKeys
  //       ? flowKeys.map((item) => ({
  //           ...response.data[item],
  //           flowId: item,
  //         }))
  //       : []
  //     setFlowData(flowData)
  //   })
  // }

  // const deleteFlow = async () => {
  //   await axios
  //     .delete(`https://flow-step-w4o7wofqcq-wl.a.run.app/delete_flow/${action?.flowId}`)
  //     .then((response) => {
  //       toast.success(response.data.message)
  //       setDeleteModal(false)
  //       fetchFlow()
  //     })
  // }

  // const deleteStep = async (id: string) => {
  //   await axios
  //     .delete(`https://flow-step-w4o7wofqcq-wl.a.run.app/delete_step/${id}`)
  //     .then((response) => {
  //       toast.success(response.data.message)
  //       fetchFlow()
  //     })
  // }

  const fetchStepsInfo = async (flowData: FlowSouce, flowId: string, refresh: boolean = false) => {
    const stepIds = flowData.steps
    if (stepIds === undefined || !stepIds.length) return
    refresh && setStepActions({...stepActions, isLoadingSteps: true})
    const uniqueDataArray = stepIds.filter((value, index, self) => self.indexOf(value) === index)
    const stepData: StepsSource[] = []
    const data: StepsSource[] = await Promise.all(
      uniqueDataArray.map(async (stepId) => {
        const response = await axios
          .get(`https://flow-step-w4o7wofqcq-wl.a.run.app/get_step/${stepId}`)
          .then((response) => response)
          .catch((err) => err)
        if (response.status === 200) {
          response.data.stepId = stepId
          stepData.push(response.data)
          return response.data
        }
        return []
      })
    )

    if (data.length > 0) {
      const filteredStepsData = data.filter((row) => Object.keys(row).length)
      flowData.stepsData = filteredStepsData
      setFlowData([flowData])
      playAllSteps(flowData)
    }
    // refresh && setStepActions({...stepActions, isLoadingSteps: false})
  }

  useEffect(() => {
    flowData === undefined && flowId && fetchFlowById(flowId)
  }, [flowId])

  const getSteps = (type: string = '') => {
    let emailData = ''

    return showReplyWindow
      .sort(function (a, b) {
        var keyA = a.index,
          keyB = b.index

        if (keyA && keyB) {
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
        }
        return 0
      })
      .map((_) =>
        type ? (
          (emailData += `${_.source}\n\n${_.answer}\n\n`)
        ) : (
          <Col xs={12} md={12}>
            <Card className='text-center border rounded h-100' style={{background: '#f6f8fa'}}>
              <Card.Body className='p-0'>
                <div className={`w-100`}>
                  <div
                    className='bg-white mh-300px'
                    style={{
                      borderRadius: '10px 10px 0 0',
                      overflowX: 'scroll',
                    }}
                  >
                    <div className='p-5 text-start'>
                      <div>
                        <b>Source : </b> {_.source}
                      </div>
                      <div className='mt-2'>
                        <b>Answer : </b> {_.answer}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )
      )
  }

  const initialValues: CreateStep = {
    stepName: '',
    actions: [],
    datasourceIds: [],
    libraryIds: [],
    promptIds: '',
    visibility: '',
    status: '',
    promptContent: '',
    promptOrigin: '',
  }

  const attachDataToSteps = async (type: string, stepId: string) => {
    setStepActions({...stepActions, isProcessingAttachStep: true})
    let url = ''
    let payload: {
      step_id?: string
      flow_id?: string
      datasource_id?: string
      library_id?: string
      prompt_id?: string
      action_id?: string
    } = {step_id: stepId}

    if (type === 'attach_step') {
      url = 'attach_step'
      payload.flow_id = action?.flowId
    } else {
      url = stepActions.type
    }
    switch (stepActions.type) {
      case 'attach_library':
        payload.library_id = values.libraryIds[0]
        break

      case 'attach_datasource':
        payload.datasource_id = values.datasourceIds[0]
        break

      case 'attach_prompt':
        if (values.promptContent !== values.promptOrigin) {
          await createNewPrompt('Custom Prompt', values.promptContent).then(
            (res) => (payload.prompt_id = res.data.prompt_id)
          )
        } else payload.prompt_id = values.promptIds
        break

      case 'attach_action':
        payload.action_id = values.actions[0]
        break
    }
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}`, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        toast.success(response.data.message)
        formik.resetForm()
        action?.flowId && fetchFlowById(action?.flowId)
        setStepActions({...stepActions, showModal: false, type: '', isProcessingAttachStep: false})
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingAttachStep: false})
        if (error.response) toast.error(`Error : ${error.response.data.error}`)
        else toast.error(`Unable to complete your request. Try again later.`)
      })
  }

  const createStep = async (values: CreateStep) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}create_step`, {
        step_name: values.stepName,
      })
      .then((response) => {
        attachDataToSteps('attach_step', response.data.step_id)
        setStepActions({...stepActions, isProcessingAttachStep: false, showModal: false, type: ''})
        toast.success(response.data.message)
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingAttachStep: false})
        toast.error(`Error : ${error.response.data.error}`)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createStepsValidationSchema[stepActions.validationSchema],
    onSubmit: (values) => {
      if (stepActions.type === 'create_step') {
        createStep(values)
      } else {
        attachDataToSteps('', stepActions.stepId || editStepAction.stepId)
      }
    },
  })

  const {values, errors, getFieldProps} = formik

  const setSelectedDatasourceIds = (id: string) => {
    let data
    if (values.datasourceIds.includes(id)) {
      data = values.datasourceIds!.filter((o) => {
        return o !== id
      })
    } else data = [...values.datasourceIds, id]
    formik.setFieldValue('datasourceIds', data)
  }

  const setSelectedLibraryIds = (id: string) => {
    let data
    if (values.libraryIds.includes(id)) {
      data = values.libraryIds!.filter((o) => {
        return o !== id
      })
    } else data = [...values.libraryIds, id]
    formik.setFieldValue('libraryIds', data)
  }

  const setSelectedPromptIds = (fieldName: string, value: any) => {
    value = JSON.parse(value)
    formik.setFieldValue('promptOrigin', value.prompt_template)
    formik.setFieldValue(
      fieldName,
      fieldName === 'promptIds' ? value.prompt_id : value.prompt_template
    )
  }

  const setSelectedActionIds = (id: string) => {
    let data
    if (values.actions.includes(id)) {
      data = values.actions!.filter((o) => {
        return o !== id
      })
    } else data = [...values.actions, id]
    formik.setFieldValue('actions', data)
  }

  const getAttachedView = () => {
    let view = <></>
    switch (stepActions.type) {
      case 'attach_library':
        view = (
          <div className='mh-400px overflow-scroll'>
            <LibraryListing
              type='selectable'
              selectedLibraryId={values.libraryIds}
              setSelectedLibraryIds={setSelectedLibraryIds}
            />
          </div>
        )
        break

      case 'attach_datasource':
        view = (
          <div className='mh-400px overflow-scroll'>
            <DataSourceListing
              type='selectable'
              selectedDatasourcesId={values.datasourceIds}
              setSelectedDatasourceIds={setSelectedDatasourceIds}
            />
          </div>
        )
        break

      case 'attach_prompt':
        view = (
          <>
            <PromptListing
              type='dropdown'
              selectedPromptId={values.promptIds}
              setSelectedPromptIds={setSelectedPromptIds}
            />
            <BootForm.Group controlId='validationFormikStepTitle' className='mt-5'>
              <BootForm.Label
                className='d-flex align-items-center fs-5 fw-semibold mb-2'
                for='promptContent'
              >
                <span className='required'>Prompt Content</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique app name'
                ></i>
              </BootForm.Label>
              <BootForm.Control
                as='textarea'
                rows={8}
                isInvalid={!!errors.promptContent}
                {...getFieldProps('promptContent')}
              />
              <BootForm.Control.Feedback type='invalid'>
                {errors.promptContent}
              </BootForm.Control.Feedback>
            </BootForm.Group>
          </>
        )
        break

      case 'attach_action':
        view = (
          <div className='mh-400px overflow-scroll'>
            <ActionLibraryListing
              type='selectable'
              selectedActionId={values.actions}
              setSelectedActionsIds={setSelectedActionIds}
            />
          </div>
        )
        break
    }
    return view
  }

  const playSteps = async (
    url: string,
    payload: {collection_name: string; question: string},
    stepId?: string,
    promptId: string = ''
  ) => {
    setStepActions({...stepActions, isProcessingPlayStep: true, stepId})
    await getPromptDataById(promptId)
      .then(async (res) => {
        payload.question = res.data.prompt_template
        payload.collection_name = payload.collection_name.replaceAll('-', '')
        await axios
          .post(`${process.env.REACT_APP_GET_ANSWER}`, JSON.stringify(payload), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            replyOnStep('', stepId, response.data)
            setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
          })
          .catch((error) => {
            setStepActions({...stepActions, isProcessingPlayStep: false, stepId: ''})
            toast.error(`Error : ${error.response.data.error}`)
          })
      })
      .catch((err) => console.log(err))
  }

  // function allProgress(proms: string | any[], progress_cb: {(p: any): void; (arg0: number): void}) {
  //   let d = 0
  //   progress_cb(0)
  //   for (const p of proms) {
  //     p.then(() => {
  //       d++
  //       progress_cb((d * 100) / proms.length)
  //     })
  //   }
  //   return Promise.all(proms)
  // }

  // function test(ms: number | undefined) {
  //   return new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       console.log(`Waited ${ms}`)
  //       resolve()
  //     }, ms)
  //   })
  // }

  // allProgress([test(1000), test(3000), test(2000), test(3500)], (p: number) => {
  //   console.log(`% Done = ${p.toFixed(2)}`)
  // })

  const playStepSequetial = () => {}

  const playAllSteps = async (flowData?: FlowSouce) => {
    if (!flowData) return

    const data: ReplyWindow[] = []
    const stepsData = flowData.stepsData
    const payload: {collection_name: string; question: string} = {
      collection_name: '',
      question: '',
    }
    let stepCountWithLibrary = 0
    if (stepsData?.length) {
      stepsData.map((item, index) => {
        if (item.prompts && item.prompts?.length > 0 && item.libraries?.length) {
          stepCountWithLibrary++
        }
      })
    }
    if (stepsData?.length) {
      const res = await Promise.all(
        stepsData.map((item, index) => {
          return new Promise<void>(async (resolve) => {
            item.prompts &&
              (await getPromptDataById(item.prompts[0])
                .then(async (res) => {
                  payload.question = `${res.data.prompt_template} ${
                    index === 0 && searchParams.get('prompt')
                  }`
                  payload.collection_name =
                    (item.libraries && item.libraries[0].replaceAll('-', '')) || ''
                  const t = await axios
                    .post(`${process.env.REACT_APP_GET_ANSWER}`, JSON.stringify(payload), {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    })
                    .then((response) => {
                      console.log(index)
                      data.push({
                        index: index,
                        stepId: item.stepId,
                        isOpen: true,
                        source: response.data.sources,
                        answer: response.data.answer,
                        promptId: item.prompts && item.prompts[0],
                      })
                      if (data.length === stepCountWithLibrary) {
                        setShowReplyWindow(data)
                        setStepActions({...stepActions, isProcessingPlayStep: false})
                      }
                      resolve()
                    })
                    .catch((error) => {
                      setStepActions({...stepActions, isProcessingPlayStep: false})
                      toast.error(`Error : ${error.response.data.error}`)
                    })
                  return t
                })
                .catch((err) => console.log(err)))
          }).then((res) => {
            console.log('res====', res)
          })
        })
      )
      // console.log('done=====', res)
    }
  }

  console.log('showReplyWindow=====', showReplyWindow)
  const detachAction = async (url: string, stepId: string, dataId: string) => {
    setStepActions({
      ...stepActions,
      isProcessingDetachStep: true,
      detachingId: url === 'detach_step' ? stepId : dataId,
    })
    let payload: {
      step_id?: string
      flow_id?: string
      datasource_id?: string
      library_id?: string
      prompt_id?: string
      action_id?: string
    } = {step_id: stepId}

    switch (url) {
      case 'Action':
        url = `${process.env.REACT_APP_DETACH_ACTION}`
        payload.action_id = dataId
        break

      case 'Library':
        url = `${process.env.REACT_APP_DETACH_LIBRARY}`
        payload.library_id = dataId
        break

      case 'Prompt':
        url = `${process.env.REACT_APP_DETACH_PROMPT}`
        payload.prompt_id = dataId
        break

      case 'Datasource':
        url = `${process.env.REACT_APP_DETACH_DATASOURCE}`
        payload.datasource_id = dataId
        break

      case 'detach_step':
        url = `${process.env.REACT_APP_DETACH_STEP_FROM_FLOW}`
        payload.flow_id = dataId
        break
    }

    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}${url}`, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        toast.success(response.data.message)
        formik.resetForm()
        setStepActions({
          ...stepActions,
          showModal: false,
          type: '',
          isProcessingDetachStep: false,
          detachingId: '',
        })
        action?.flowId && fetchFlowById(action?.flowId)
      })
      .catch((error) => {
        setStepActions({...stepActions, isProcessingDetachStep: false, detachingId: ''})
        toast.error(`Error : ${error.response.data.error}`)
      })
  }

  const replyOnStep = (
    action: string = '',
    stepId: string = '',
    qa: {sources: string; answer: string},
    promptId?: string
  ) => {
    // const currentStepIndex = showReplyWindow.findIndex((item) => item.stepId === stepId)
    // if (currentStepIndex === -1) {
    //   setShowReplyWindow([
    //     ...showReplyWindow,
    //     {
    //       stepId,
    //       isOpen: action ? false : true,
    //       source: qa.sources,
    //       answer: qa.answer,
    //       promptId,
    //     },
    //   ])
    // } else {
    //   const updatedTodo = {
    //     ...showReplyWindow[currentStepIndex],
    //     stepId,
    //     isOpen: action ? false : true,
    //     source: qa.sources,
    //     answer: qa.answer,
    //     promptId,
    //   }
    //   const newTodos = [
    //     ...showReplyWindow.slice(0, currentStepIndex),
    //     updatedTodo,
    //     ...showReplyWindow.slice(currentStepIndex + 1),
    //   ]
    //   setShowReplyWindow(newTodos)
    // }
  }

  const fielterCurrentStepAnswer = (stepId: string) =>
    showReplyWindow?.filter((item) => item.stepId === stepId)

  return (
    <div className={`card`}>
      <div className='card-header bg-white'>
        <div className='d-flex align-items-center'>
          <h3 className='card-title align-items-start flex-column col'>
            <span className='card-label fw-bold fs-3 mb-1'>{searchParams.get('prompt')}</span>
          </h3>
        </div>
        <div className='d-flex align-items-center position-relative'>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip data-bs-theme='dark'>Mail To</Tooltip>}
          >
            <a
              href={`mailto:myemailexample@gmail.com?body=${getSteps('string')}`}
              className='btn-icon btn-light-primary rounded-circle btn-light ms-4'
            >
              <i className='bi bi-envelope-at fs-1'></i>
            </a>
          </OverlayTrigger>
        </div>
      </div>
      <Row md={3} className='g-6 mb-4 m-0 p-6'>
        {stepActions.isProcessingPlayStep ? (
          <div className='d-flex justify-content-center w-100 align-items-center h-250px'>
            <img style={{width: '70px', objectFit: 'contain'}} src={loadingImg} alt='loading...' />
          </div>
        ) : (
          showReplyWindow && getSteps()
        )}
      </Row>
    </div>
  )
}

export default FlowWebhook
