/* eslint-disable jsx-a11y/anchor-is-valid */

import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {useSelector} from 'react-redux'

const HeaderUserMenu = () => {
  const {currentUser} = useAuth()

  interface UserInfo {
    id: string
    displayName: string
    email: string
    photoURL: string
    // add any other fields you have in your state.userInfo.userInfo.user object
  }

  interface RootState {
    userInfo: {
      userInfo: {
        user: UserInfo
      }
    }
    // add any other fields you have in your root state
  }

  const loginData: UserInfo = useSelector((state: RootState) => state.userInfo.userInfo.user)

  const handleLogout = () => {
    localStorage.clear()

    window.location.reload()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {loginData?.photoURL === '' ? (
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
            ) : (
              <img alt='Logo' src={loginData?.photoURL} />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {loginData?.displayName} {currentUser?.first_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {loginData?.email} {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <Link to={'/folder'} className='menu-link px-5'>
          File Manager
        </Link>
      </div>
      
      <div className='menu-item px-5'>
        <Link to={'/actiontype'} className='menu-link px-5'>
          Action Library
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/prompt'} className='menu-link px-5'>
          Prompt Library
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={handleLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
