import * as React from 'react'
interface Props {
  classes: string
}
const PromptIcon = ({classes}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    x={0}
    y={0}
    viewBox='0 0 512.052 512.052'
    xmlSpace='preserve'
    className={classes}
  >
    <g>
      <path
        d='M467.526 428.926c28.8-30.9 44.5-69.1 44.5-108.9 0-49.8-24.6-94.9-64-126.9-.9 114.1-108.2 206.9-240 206.9h-89.2c34.5 56.9 104.6 96 185.2 96h192c6.5 0 12.3-3.9 14.8-9.9s1.1-12.9-3.5-17.4l-39.8-39.8z'
        style={{}}
        fill='#64b5f6'
        data-original='#64b5f6'
      />
      <path
        d='M208.026 16.026c-114.7 0-208 78.9-208 176 0 39.8 15.7 77.9 44.5 108.9l-39.8 39.8c-4.6 4.6-6 11.5-3.5 17.4 2.5 6 8.3 9.9 14.8 9.9h192c114.7 0 208-78.9 208-176s-93.3-176-208-176z'
        style={{}}
        fill='#2196f3'
        data-original='#2196f3'
      />
      <path
        d='M304.026 144.026h-192c-8.8 0-16 7.2-16 16s7.2 16 16 16h192c8.8 0 16-7.2 16-16s-7.2-16-16-16zM240.026 208.026h-128c-8.8 0-16 7.2-16 16s7.2 16 16 16h128c8.8 0 16-7.2 16-16s-7.2-16-16-16z'
        style={{}}
        fill='#fafafa'
        data-original='#fafafa'
      />
    </g>
  </svg>
)
export default PromptIcon
