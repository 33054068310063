import {MouseEvent, SetStateAction, useEffect, useState} from 'react'
import {Button, Card, Col, Image, Row} from 'react-bootstrap'
import '../../App.css'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {AiOutlineEye} from 'react-icons/ai'
import {BsTrash} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import loadingimg from '../../../_metronic/assets/loading.gif'

interface Props {
  type?: string
  selectedDatasourcesId?: string[]
  setSelectedDatasourceIds: any
}

interface Datasource {
  file_paths: string[]
  name: string
  datasourceId: string
}

function DataSourceListing({type, selectedDatasourcesId, setSelectedDatasourceIds}: Props) {
  const [data, setData] = useState([])
  const [getDataSelectValue, setgetDataSelectValue] = useState()
  const [searchDataSourceName, setsearchDataSourceName] = useState('')
  const [modalIndexValue, setModalIndexValue] = useState(1)
  const [previousPaths, setPreviousPaths] = useState([])
  const [previousFolders, setPreviousFolders] = useState([])
  const [allDataSources, setAllDataSources] = useState<Datasource[]>([])
  const [modalValue, setModalValue] = useState(true)
  const [dataSourceIdValue, setDataSourceIdValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [dataSources, setDataSources] = useState([])
  const [allfolders, setAllFolders] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [dataSourcename, setDataSourcename] = useState()
  const [dataSourcepath, setDataSourcepath] = useState(false)
  const [editid, seteditid] = useState('')
  const [editName, seteditName] = useState(undefined)
  let [currentPath, setCurrentPath] = useState('uploaded_folders')

  const ids = selectedDatasourcesId ?? []

  const getAllDataSources = async () => {
    await axios.get(`${process.env.REACT_APP_DATASOURCE_LIST}/datasources`).then((response) => {
      const dataSourceKeys = Object.keys(response.data)
      const dataSource = dataSourceKeys
        ? dataSourceKeys.map((item) => ({
            ...response.data[item],
            datasourceId: item,
          }))
        : []
      setAllDataSources(dataSource)
    })
    setIsLoading(false)
  }

  useEffect(() => {
    getAllDataSources()
  }, [])

  const filterDataSource = allDataSources?.filter((item: any) =>
    item.name.toLowerCase().includes(searchDataSourceName.toLocaleLowerCase())
  )

  return (
    <div className='card card-flush w-100'>
      <div className='card-header p-0'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <input
              type='text'
              data-kt-filemanager-table-filter='search'
              className='form-control form-control-solid w-250px ps-15'
              placeholder='Search Data Source'
              onChange={(e) => setsearchDataSourceName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div
        className={`dataTables_wrapper dt-bootstrap4 no-footer dataSource-table ${
          type === 'selectable' && 'mx-0'
        }`}
      >
        {isLoading ? (
          <div className='d-flex justify-content-center w-100 align-items-center h-250px'>
            <img style={{width: '70px', objectFit: 'contain'}} src={loadingimg} alt='loading...' />
          </div>
        ) : (
          <Row md={3} className='g-5 mb-5 mx-0'>
            {filterDataSource?.length ? (
              filterDataSource.map((_) => (
                <Col>
                  <Card className='text-center border border-secondary cursor-pointer'>
                    <label
                      className={`d-flex flex-stack cursor-pointer w-100 ${
                        type !== 'selectable' && 'py-8 flex-column'
                      }`}
                    >
                      <span
                        className='form-check form-check-custom form-check-solid position-absolute'
                        style={{right: 6, top: 6}}
                      >
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value={_?.datasourceId}
                          onChange={() => setSelectedDatasourceIds(_?.datasourceId)}
                          checked={selectedDatasourcesId?.includes(_?.datasourceId)}
                        />
                      </span>
                      <Card.Body className='p-3'>
                        <div className='row no-gutters'>
                          <div className='col-md-auto'>
                            <div className='symbol symbol-75px m-0 w-100'>
                              <span className='symbol-label bg-light-primary'>
                                <i className='ki-duotone ki-abstract-26 fs-3x text-primary'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </div>
                          </div>
                          <div className='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                            <p className='card-title pt-1'>{_.name}</p>
                          </div>
                        </div>
                      </Card.Body>
                    </label>
                  </Card>
                </Col>
              ))
            ) : (
              <Col className='col-12 col-md-12 text-center'>
                <Image
                  src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                  className='mw-200px'
                />
                <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                <div className='fs-6 pb-5'>Start creating new folders or uploading a new file!</div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}

export default DataSourceListing
