import {useEffect} from 'react'
import '../../App.css'
import axios from 'axios'
import {Button, Modal, Form as BootForm} from 'react-bootstrap'
import {Form, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'

interface Props {
  promptData: {isShowModal: boolean; promptId: string}
  editPromptAction: any
}

interface EditPromptFormik {
  promptTitle: string
  promptContent: string
}

function EditPrompt({promptData, editPromptAction}: Props) {
  const editPromptData = async () => {
    await axios
      .put(`${process.env.REACT_APP_FILE_MANAGER_LIST}/edit_prompt/${promptData.promptId}`, {
        prompt: values.promptTitle,
        prompt_template: values.promptContent,
      })
      .then((response) => {
        formik.setFieldValue('promptTitle', response.data.prompt)
        formik.setFieldValue('promptContent', response.data.prompt_template)
        toast.success(response.data.message)
      })
      .catch((error) => {
        toast.error(`Error : ${error.response.data.error}`)
      })
    editPromptAction({...promptData, isShowModal: false, promptId: ''})
    formik.resetForm()
  }

  const getPromptData = () => {
    axios
      .get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/prompt/${promptData.promptId}`)
      .then((response) => {
        formik.setFieldValue('promptTitle', response.data.prompt)
        formik.setFieldValue('promptContent', response.data.prompt_template)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    promptData.promptId && getPromptData()
  }, [promptData.promptId])

  const validationSchema = Yup.object({
    promptTitle: Yup.string().required().label('Prompt Title'),
    promptContent: Yup.string().required().label('Prompt Content'),
  })

  const initialValues: EditPromptFormik = {
    promptTitle: '',
    promptContent: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editPromptData()
    },
  })

  const {values, errors, getFieldProps} = formik

  return (
    <Modal show={promptData.isShowModal} centered>
      <Modal.Header className='p-4 border-0'>
        <h3 className='mb-0 m-auto'>Edit Prompt</h3>
        <button
          type='button'
          className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
          onClick={() => editPromptAction({...promptData, isShowModal: false})}
        >
          <KTIcon iconName='cross' className='fs-2' />
        </button>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form>
            <div className='current'>
              <div className='w-100'>
                <BootForm.Group controlId='validationFormikStepTitle'>
                  <BootForm.Label
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                    for='promptTitle'
                  >
                    <span className='required'>Prompt Title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </BootForm.Label>
                  <BootForm.Control
                    type='text'
                    isInvalid={!!errors.promptTitle}
                    {...getFieldProps('promptTitle')}
                  />
                  <BootForm.Control.Feedback type='invalid'>
                    {errors.promptTitle}
                  </BootForm.Control.Feedback>
                </BootForm.Group>
                <BootForm.Group controlId='validationFormikStepTitle' className='mt-5'>
                  <BootForm.Label
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                    for='promptContent'
                  >
                    <span className='required'>Prompt Content</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your unique app name'
                    ></i>
                  </BootForm.Label>
                  <BootForm.Control
                    as='textarea'
                    rows={8}
                    isInvalid={!!errors.promptContent}
                    {...getFieldProps('promptContent')}
                  />
                  <BootForm.Control.Feedback type='invalid'>
                    {errors.promptContent}
                  </BootForm.Control.Feedback>
                </BootForm.Group>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <Button variant='primary' className='btn-sm' onClick={() => formik.handleSubmit()}>
          Update
        </Button>
        {/* <Button
          variant='light'
          className='btn-sm'
          onClick={() => editPromptAction({...promptData, isShowModal: false})}
        >
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default EditPrompt
