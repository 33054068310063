interface LoadingButtonIconProps {
  isLoading: boolean
  submitButtonAction: any
  cancelButtonAction: any
}

const LoadingButtonIcon = ({
  isLoading,
  submitButtonAction,
  cancelButtonAction,
}: LoadingButtonIconProps) => {
  return (
    <>
      {isLoading ? (
        <button className='btn btn-light-primary me-3' id='kt_file_manager_add_folder' disabled>
          <span className='indicator-progress d-flex align-items-center'>
            <span className='spinner-border spinner-border-sm align-middle me-2'></span>
            Creating...
          </span>
        </button>
      ) : (
        <>
          <button
            className='btn btn-light-primary w-100 btn-sm p-1'
            id='kt_file_manager_add_folder'
            onClick={() => submitButtonAction()}
          >
            <span className='indicator-label'>
              <i className='ki-duotone ki-check fs-1'></i>
            </span>
          </button>
          <button
            onClick={() => cancelButtonAction(false)}
            className='btn btn-light-danger w-100 btn-sm p-1'
            id='kt_file_manager_cancel_folder'
          >
            <span className='indicator-label'>
              <i className='ki-duotone ki-cross fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </button>
        </>
      )}
    </>
  )
}
export default LoadingButtonIcon
