import React, {useEffect, useState} from 'react'
import {Card, Col, Form, Image, Modal, Row} from 'react-bootstrap'
import '../../App.css'
import axios from 'axios'
import loading from '../../../_metronic/assets/loading.gif'
import {AiOutlineEye} from 'react-icons/ai'
import {BsFillFileCodeFill, BsTrash} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import EmailIcon from '../../../_metronic/assets/svgIcon/EmailIcon'
import SearchIcon from '../../../_metronic/assets/svgIcon/SearchIcon'
import ApiIcon from '../../../_metronic/assets/svgIcon/ApiIcon'
import CrawlWebsiteIcon from '../../../_metronic/assets/svgIcon/CrawlWebsiteIcon'
import CrawlWebpageIcon from '../../../_metronic/assets/svgIcon/CrawlWebpageIcon'

function ActionLibraryContainer() {
  const [updateId, setUpdateId] = useState()
  const [file, setFile] = useState()
  const [show, setShow] = useState(false)
  const [data, setData] = useState([])
  const [tempData, setTempData] = useState()
  const [updateAction, setUpdateAction] = useState()
  const [ActiontypeIndex, setActiontypeIndex] = useState(1)
  const [getDataSelectValue, setgetDataSelectValue] = useState()
  const [dataSourceName, setDataSourceName] = useState('')
  const [actionFormValue, setActionFormValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [actionTypes, setActionTypes] = useState([
    {title: 'Email', action: 'email', icon: <EmailIcon classes='w-50px' />},
    {title: 'API', action: 'api', icon: <ApiIcon classes='w-50px' />},
    {
      title: 'Search',
      action: 'search',
      icon: <SearchIcon classes='w-50px' />,
    },
    {title: 'Crawl Webpage', action: 'crawl_webpage', icon: <CrawlWebpageIcon classes='w-50px' />},
    {title: 'Crawl Website', action: 'crawl_website', icon: <CrawlWebsiteIcon classes='w-50px' />},
  ])

  const baseURL = `${process.env.REACT_APP_FILE_MANAGER_LIST}/get_saved_forms`

  const handleToggle = () => setShow(!show)

  const getData = () => {
    axios.get(baseURL).then((response) => {
      setData(response.data.forms)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleChange = (e) => {
    setFile(e.target.files[0])
  }
  const getDataSourceValue = (e) => {
    setgetDataSelectValue(e)
  }

  const ActionTypeIndexChange = () => {
    setActiontypeIndex(2)
  }

  const size = (file?.size / 1000000).toFixed(2)

  const filterDataSource = data.filter((item) =>
    item.action_type.toLowerCase().includes(dataSourceName.toLocaleLowerCase())
  )

  const AddFolderData = async () => {
    setActiontypeIndex(1)
    const result = await axios.post(
      `${process.env.REACT_APP_FILE_MANAGER_LIST}/submit_action_forms`,
      {
        action_type: getDataSelectValue,
        html_form: actionFormValue,
      }
    )
    if (result?.status == 200) {
      getData()
      alert('Action type uploaded')
    }
  }

  const handleView = (id) => {
    setUpdateId(id)
    fetch(`${process.env.REACT_APP_FILE_MANAGER_LIST}/get_form_id?id=${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setTempData(data.form.html_form)
        setUpdateAction(data.form)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleDelete = (id) => {
    var answer = window.confirm('Are you sure you want to delete this?')
    if (answer) {
      fetch(`${process.env.REACT_APP_FILE_MANAGER_LIST}/delete_form_id?id=${id}`, {
        method: 'DELETE',
      })
        .then((response) => response.json())
        .then((data) => {
          getData()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const handleUpdate = (id, action, html) => {
    axios
      .put(`${process.env.REACT_APP_FILE_MANAGER_LIST}/edit_form_id?id=${id}`, {
        action_type: action,
        html_form: html,
      })
      .then((response) => getData())
  }

  return (
    <div>
      <div className='card card-flush'>
        <div className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat card_style'>
          <div className='card-header p-10 pb-0 bg-white'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-circle me-5'>
                <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed'>
                  <i className='ki-duotone ki-abstract-47 fs-2x text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <h2 className='mb-1'>Action Library</h2>
                <div className='text-muted fw-bold'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-header pt-8'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search'
                onChange={(e) => setDataSourceName(e.target.value)}
              />
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              <button
                id='kt_file_manager_new_folder'
                data-bs-target='#kt_modal_create_app'
                data-bs-toggle='modal'
                type='button'
                className='btn btn-flex  btn-light-primary'
              >
                <i className='ki-duotone ki-add-folder fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Create Action
              </button>
            </div>

            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-filemanager-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
          </div>
        </div>
        <div
          className='dataTables_wrapper dt-bootstrap4 no-footer mt-6 dataSource-table'
          id='kt_file_manager_list_wrapper'
        >
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <img
                style={{height: '100px', width: '100px', objectFit: 'cover'}}
                src={isLoading}
                alt='loading...'
              />
            </div>
          ) : (
            <Row xs md={3} className='g-5 mb-5'>
              {filterDataSource.length ? (
                filterDataSource.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer'>
                      <Card.Body className='p-3'>
                        <div className='row no-gutters'>
                          <div className='col-md-auto'>
                            <div className='symbol symbol-75px m-0 w-100'>
                              <div className='symbol-label bg-light-primary p-5'>
                                {
                                  actionTypes.filter((item) => item.action === _?.action_type)[0]
                                    ?.icon
                                }
                              </div>
                            </div>
                          </div>
                          <div className='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                            <p className='card-title pt-1'>
                              {
                                actionTypes.filter((item) => item.action === _?.action_type)[0]
                                  ?.title
                              }
                            </p>
                            <div className='d-flex gap-2 col-12'>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                                data-kt-menu-placement='bottom-end'
                                id='kt_file_manager_new_folder'
                                data-kt-menu-trigger='click'
                                data-bs-target='#viewActionModal'
                                data-bs-toggle='modal'
                                onClick={() => handleView(_.id)}
                              >
                                <AiOutlineEye className='openeyeIcon' />
                              </button>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary ActionIcon'
                                data-kt-menu-trigger='click'
                                data-bs-target='#editActionModal'
                                data-bs-toggle='modal'
                                data-kt-menu-placement='bottom-end'
                                onClick={() => handleView(_.id)}
                              >
                                <BiEdit className='openeyeIcon' />
                              </button>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary ActionIcon'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                onClick={() => handleDelete(_.id)}
                              >
                                <BsTrash className='openeyeIcon' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className='col-12 col-md-12 text-center'>
                  <Image
                    src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                    className='mw-200px'
                  />
                  <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                  <div className='fs-6 pb-5'>
                    Start creating new folders or uploading a new file!
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>

      {/* modal */}
      <div className='modal fade' id='kt_modal_create_app' tabindex='-1' aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          {/*begin::Modal content*/}
          <div className='modal-content'>
            {/*begin::Modal header*/}
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>Create Action Types</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                <span className='required'>Select Action Types</span>
                <span className='ms-1' data-bs-toggle='tooltip' title='Select your app category'>
                  <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
              </label>
              <Row xs md={5} className='g-6 mb-5 flex-row flex-nowrap overflow-scroll'>
                {actionTypes.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer align-items-center'>
                      <label
                        class='d-flex flex-stack p-4 py-8  cursor-pointer flex-column w-100'
                        onClick={(e) => getDataSourceValue(e.target.value)}
                      >
                        <span
                          class='form-check form-check-custom form-check-solid position-absolute'
                          style={{right: 6, top: 6}}
                        >
                          <input
                            class='form-check-input'
                            type='checkbox'
                            name='category'
                            value={_?.action}
                            checked={getDataSelectValue === _?.action}
                            onChange={(e) => getDataSourceValue(e.target.value)}
                          />
                        </span>
                        {_.icon}
                        <Card.Body className='p-0'>
                          <span className='d-flex align-items-center justify-content-center'>
                            <span className='symbol symbol-50px'></span>
                            <span className='d-flex flex-column'>
                              <span className='fw-bold fs-6'>{_?.title}</span>
                            </span>
                          </span>
                        </Card.Body>
                      </label>
                    </Card>
                  </Col>
                ))}
              </Row>
              <div className='w-100'>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                    <span className='required'>Enter Content</span>
                    <span
                      className='ms-1'
                      data-bs-toggle='tooltip'
                      title='Select your app category'
                    >
                      <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </span>
                  </label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    className='create-action-type-form'
                    value={actionFormValue}
                    onChange={(e) => setActionFormValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer pt-2 border-0 px-8'>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-kt-stepper-action='next'
                data-bs-dismiss='modal'
                onClick={AddFolderData}
              >
                save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* view Modal */}
      <div className='modal fade' id='viewActionModal' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div class='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>View Action Type</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body py-lg-10 px-lg-10 pb-0'>
              <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='fv-row h-300px mh-300px overflow-scroll'>
                      <div dangerouslySetInnerHTML={{__html: tempData}} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
      {/* end view modal */}

      {/* edit Modal */}
      <div className='modal fade' id='editActionModal' tabindex='-1' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div className='modal-header py-5 border-0 text-center'>
              <h2 className='mb-0 m-auto'>Edit Action Type</h2>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                data-bs-dismiss='modal'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>

            <div className='modal-body '>
              <div
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                <div className='flex-row-fluid py-lg-5 px-lg-15'>
                  <form className='form' novalidate='novalidate' id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row'>
                          <input type='hidden' value={updateAction?.action_type}></input>
                          <Form.Control
                            type='text'
                            as='textarea'
                            className='h-300px w-100'
                            value={updateAction?.html_form}
                            onChange={(event) =>
                              setUpdateAction({
                                ...updateAction,
                                html_form: event.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          Back
                        </button>
                      </div>

                      <div>
                        <button
                          type='button'
                          className='btn btn-lg btn-primary'
                          data-kt-stepper-action='next'
                          onClick={() =>
                            handleUpdate(
                              updateId,
                              updateAction?.action_type,
                              updateAction?.html_form
                            )
                          }
                          data-bs-dismiss='modal'
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit modal */}

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-center'
        centered
        show={show}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title className='file-upload-text'>Upload Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='file-btn'>
            <input
              type='file'
              name='file-input'
              id='file'
              className='file-input__input'
              onChange={handleChange}
            />
            <label className='file-input__label mb-4 ml-4' for='file'>
              <span>Attach file</span>
            </label>
          </form>
          {file?.name ? (
            <div className='dropzone-items wm-200px margin'>
              <div className='dropzone-file'>
                <div
                  className='dropzone-filename text-dark d-flex'
                  style={{justifyContent: 'space-between'}}
                >
                  <span data-dz-name=''>
                    {file.name} (<strong>{size}</strong> {size >= 1024 ? 'GB' : 'MB'})
                  </span>
                  <div className='dropzone-progress'>
                    <div className='progress bg-gray-300'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        aria-valuemin='0'
                        aria-valuemax='100'
                        aria-valuenow='0'
                        data-dz-uploadprogress=''
                      ></div>
                    </div>
                    {size <= 1 && (
                      <div className='dropzone-toolbar d-flex'>
                        <span className='dropzone-start'>
                          <i className='ki-duotone ki-to-right fs-1'></i>
                        </span>
                        <span className='dropzone-cancel' data-dz-remove=''>
                          <i className='ki-duotone ki-cross fs-2'>
                            <span className='path1'></span> <span className='path2'></span>
                          </i>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {size > 1 ? (
                  <div
                    className='dropzone-error mt-0 mb-4'
                    data-dz-errormessage=''
                    style={{color: 'red'}}
                  >
                    File is too big ({size} {size >= 1024 ? 'GB' : 'MB'}.) Max filesize: 1MB.
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className='file-text-main '>
            <Form.Text className='file-text form-text fs-6 text-muted'>
              Max file size is 1MB per file.
            </Form.Text>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

export default ActionLibraryContainer
