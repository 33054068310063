import {ReactNode} from 'react'
import {Button} from 'react-bootstrap'

interface LoadingButtonProps {
  isLoading?: boolean
  submitButtonAction: any
  loadingButtonTitle?: string
  children: ReactNode
  buttonClasses: string
}

const LoadingButton = ({
  children,
  isLoading,
  submitButtonAction,
  buttonClasses,
}: LoadingButtonProps) => {
  return (
    <Button
      type='button'
      variant='primary'
      className={`${buttonClasses} position-relative d-flex align-items-center`}
      onClick={submitButtonAction}
      disabled={isLoading}
    >
      {isLoading && (
        <span className='indicator-progress d-flex align-items-center me-2'>
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      )}
      {children}
    </Button>

    // <>

    //   {isLoading ? (
    //     <Button variant='primary' className='me-3 btn-sm' disabled>
    //       <span className='indicator-progress d-flex align-items-center'>
    //         <span className='spinner-border spinner-border-sm align-middle me-2'></span>
    //         {loadingButtonTitle || 'Creating...'}
    //       </span>
    //     </Button>
    //   ) : (
    //     <Button
    //       type='button'
    //       variant='primary'
    //       className='btn-sm me-3'
    //       onClick={submitButtonAction}
    //     >
    //       {children}
    //     </Button>
    //   )}
    // </>
  )
}
export default LoadingButton
