import AsyncStorage from "redux-persist/lib/storage";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { userReducer } from "./Auth/AuthReducer";

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
};

const appReducer = combineReducers({
  userInfo: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);
