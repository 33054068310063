import {useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import ApiIcon from '../../../assets/svgIcon/ApiIcon'
import SearchIcon from '../../../assets/svgIcon/SearchIcon'
import LibraryIcon from '../../../assets/svgIcon/LibraryIcon'

interface ActionType {
  title: string;
  action: string;
  icon: JSX.Element;
}

interface Props {
  fieldName?: string;
  actionType: string;
  setActionType: any;
  setDatasourceType: (val: string) => void;
}

const DatasourceTypes = ({ fieldName, actionType, setActionType, setDatasourceType }: Props) => {
  const actionTypes: ActionType[] = [
    { title: 'Files', action: 'files', icon: <LibraryIcon classes='w-45px' /> },
    { title: 'Webpages', action: 'webpages', icon: <ApiIcon classes='w-45px' /> },
    { title: 'Website', action: 'website', icon: <SearchIcon classes='w-45px' /> },
  ];

  const handleSelectActionType = (selectedActionType: ActionType) => {
    setActionType(selectedActionType.action);
    setDatasourceType(selectedActionType.title);
  };

  return (
    <div className='fv-row w-100'>
      <label className='d-flex align-items-center fs-5 fw-semibold'>
        <span className='required'>Select DataSources</span>
        <span className='ms-1' data-bs-toggle='tooltip' title='Select your app category'>
          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
      </label>
      <Row md={3} className='g-6 flex-row flex-nowrap overflow-scroll mt-0'>
        {actionTypes.map((_) => (
          <Col >
            <Card className='text-center border border-secondary cursor-pointer align-items-center pt-2' onClick={()=>handleSelectActionType(_)}>
              <label
                className='d-flex flex-stack py-6 cursor-pointer flex-column w-100'
                onClick={(e) => setActionType(fieldName, (e.target as HTMLInputElement).value)}
              >
                <span
                  className='form-check form-check-custom form-check-solid position-absolute'
                  style={{right: 6, top: 6}}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='category'
                    value={_?.action}
                    checked={actionType === _?.action}
                    onChange={(e) => setActionType(fieldName, e.target.value)}
                  />
                </span>
                {_.icon}
                <Card.Body className='p-0'>
                  <span className='d-flex align-items-center justify-content-center'>
                    <span className='symbol symbol-50px'></span>
                    <span className='d-flex flex-column'>
                      <span className='fw-bold fs-6 pt-2'>{_?.title}</span>
                    </span>
                  </span>
                </Card.Body>
              </label>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default DatasourceTypes
