import React, {useEffect, useState} from 'react'
import {Card, Col, Form, Image, Modal, Row} from 'react-bootstrap'
import '../../App.css'
import axios from 'axios'
import loading from '../../../_metronic/assets/loading.gif'
import {AiOutlineEye} from 'react-icons/ai'
import {BsFillFileCodeFill, BsTrash} from 'react-icons/bs'
import {BiEdit} from 'react-icons/bi'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import EmailIcon from '../../../_metronic/assets/svgIcon/EmailIcon'
import SearchIcon from '../../../_metronic/assets/svgIcon/SearchIcon'
import ApiIcon from '../../../_metronic/assets/svgIcon/ApiIcon'
import CrawlWebsiteIcon from '../../../_metronic/assets/svgIcon/CrawlWebsiteIcon'
import CrawlWebpageIcon from '../../../_metronic/assets/svgIcon/CrawlWebpageIcon'
import loadingImg from '../../../_metronic/assets/loading.gif'

interface Props {
  type?: string
  selectedActionId?: string[]
  setSelectedActionsIds: any
}

interface ActionLibraryData {
  action_type: string
  html_form: string
  id: string
}

function ActionLibraryListing({type, selectedActionId, setSelectedActionsIds}: Props) {
  const [updateId, setUpdateId] = useState()
  const [file, setFile] = useState()
  const [show, setShow] = useState(false)
  const [actionLibraryData, setActionLibraryData] = useState<ActionLibraryData[]>([])
  const [tempData, setTempData] = useState()
  const [updateAction, setUpdateAction] = useState()
  const [ActiontypeIndex, setActiontypeIndex] = useState(1)
  const [getDataSelectValue, setgetDataSelectValue] = useState()
  const [dataSourceName, setDataSourceName] = useState('')
  const [actionFormValue, setActionFormValue] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [actionTypes, setActionTypes] = useState([
    {title: 'Email', action: 'email', icon: <EmailIcon classes='w-50px' />},
    {title: 'API', action: 'api', icon: <ApiIcon classes='w-50px' />},
    {
      title: 'Search',
      action: 'search',
      icon: <SearchIcon classes='w-50px' />,
    },
    {title: 'Crawl Webpage', action: 'crawl_webpage', icon: <CrawlWebpageIcon classes='w-50px' />},
    {title: 'Crawl Website', action: 'crawl_website', icon: <CrawlWebsiteIcon classes='w-50px' />},
  ])

  const getData = () => {
    axios.get(`${process.env.REACT_APP_FILE_MANAGER_LIST}/get_saved_forms`).then((response) => {
      setActionLibraryData(response.data.forms)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const filterLibraryData = actionLibraryData.filter((item) =>
    item.action_type.toLowerCase().includes(dataSourceName.toLocaleLowerCase())
  )

  return (
    <div>
      <div className='card card-flush'>
        <div className='card-header p-0'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <i className='ki-duotone ki-magnifier fs-1 position-absolute ms-6'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='text'
                data-kt-filemanager-table-filter='search'
                className='form-control form-control-solid w-250px ps-15'
                placeholder='Search'
                onChange={(e) => setDataSourceName(e.target.value)}
              />
            </div>
          </div>

          <div className='card-toolbar'></div>
        </div>
        <div
          className={`dataTables_wrapper dt-bootstrap4 no-footer dataSource-table ${
            type === 'selectable' && 'mx-0'
          }`}
        >
          {isLoading ? (
            <div className='d-flex justify-content-center w-100 align-items-center h-250px'>
              <img
                style={{width: '70px', objectFit: 'contain'}}
                src={loadingImg}
                alt='loading...'
              />
            </div>
          ) : (
            <Row md={3} className='g-5 mb-5 mx-0'>
              {filterLibraryData.length ? (
                filterLibraryData.map((_) => (
                  <Col>
                    <Card className='text-center border border-secondary cursor-pointer'>
                      <label
                        className={`d-flex flex-stack cursor-pointer w-100 ${
                          type !== 'selectable' && 'py-8 flex-column'
                        }`}
                      >
                        <span
                          className='form-check form-check-custom form-check-solid position-absolute'
                          style={{right: 6, top: 6}}
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={_?.id}
                            onChange={() => setSelectedActionsIds(_?.id)}
                            checked={selectedActionId?.includes(_?.id)}
                          />
                        </span>
                        <Card.Body className='p-3'>
                          <div className='row no-gutters'>
                            <div className='col-md-auto'>
                              <div className='symbol symbol-75px m-0 w-100'>
                                <div className='symbol-label bg-light-primary p-5'>
                                  {
                                    actionTypes.filter((item) => item.action === _?.action_type)[0]
                                      ?.icon
                                  }
                                </div>
                              </div>
                            </div>
                            <div className='col-md text-start ps-0 d-flex flex-column justify-content-between'>
                              <p className='card-title pt-1'>
                                {
                                  actionTypes.filter((item) => item.action === _?.action_type)[0]
                                    ?.title
                                }
                              </p>
                              <div className='d-flex gap-2 col-12'></div>
                            </div>
                          </div>
                        </Card.Body>
                      </label>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col className='col-12 col-md-12 text-center'>
                  <Image
                    src={toAbsoluteUrl('media/illustrations/sketchy-1/5.png')}
                    className='mw-200px'
                  />
                  <div className='fs-1 fw-bolder text-dark mb-4'>No items found.</div>
                  <div className='fs-6 pb-5'>
                    Start creating new folders or uploading a new file!
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>
    </div>
  )
}

export default ActionLibraryListing
